
import {
    gql
  } from "@apollo/client";
import { privateClient as client } from '..';
import { commonErrorHandling } from '../Utils/index';
export default function* categorieFilterListSaga(data) {
   

    const query = gql`
    query categoriesAllItem($service_id:Int){
        categoriesAll(service_id:$service_id){
          id,
          name,
          parent_id
          categories{
              id,
              name,
              parent_id
          }
        }
      }
    `;
    const response = yield client
      .query({
        query: query,
        variables:{
          service_id:data.service_id,
          }

      })
      .then(result => {
        return {
            success:true,
            payload:result
        };
      }
    ).
    catch(e => {
     
      commonErrorHandling( e)
        return {
            success:false,
            payload:e
        };
    })
    
    return response;
      
}
