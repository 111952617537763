import { all } from "redux-saga/effects";
import login from './login';
import service from './service';
import product from './product';
import attribute from './attribute';
import category from './category';
import ftpConnection from './ftpConnection';
import user from './user';
import catalogRule from './catalogRule';
import settings from './settings';

export default function* generalSaga() {
  yield all([login(), service(), product(), attribute(), category(), ftpConnection(), user(), catalogRule(),settings()]);
}
