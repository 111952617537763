import {
    VIEW_APPLIED_CATALOG_RULE_FAILED,
    VIEW_APPLIED_CATALOG_RULE_PROCESSING,
    VIEW_APPLIED_CATALOG_RULE_REQUESTED,
    VIEW_APPLIED_CATALOG_RULE_SUCCESSED
} from '../../../constant/actionTypes';

const initialState = {
    data: {},
    isProcessing: null,
    isSuccess: null,
    hasErrors: null,
  };
  
  export const viewAppliedCatalogRuleStore= (state = initialState, action) => {
    switch (action.type) {
        
      case VIEW_APPLIED_CATALOG_RULE_REQUESTED: {
        return {
          ...state,
          result: null,
          error: null,
          loading: true,
          isSuccess: false,
          requested: true,
        };
      }
      case VIEW_APPLIED_CATALOG_RULE_PROCESSING: {
        return {
          ...state,
          result: null,
          error: null,
          loading: true,
          isSuccess: false,
          requested: false,
        };
      }
      case VIEW_APPLIED_CATALOG_RULE_SUCCESSED: {
        return {
          result: action.data,
          error: null,
          loading: false,
          isSuccess: true,
          requested: false,
        };
      }
      case VIEW_APPLIED_CATALOG_RULE_FAILED: {
        return {
          result: null,
          error: action.data,
          loading: false,
          isSuccess: false,
          requested: false,
        };
      }
  
      default: {
        return {
          ...state,
        };
      }
    }
  };
  