
import {
    gql
  } from "@apollo/client";
import { publicClient as client } from '..';

export default function* forgotPasswordSaga(data) {
 
    const query = gql`
        mutation forgotPasswordItem($email:String!){
            forgotPassword(email: $email) {
                message
            }
        }
    `;

    const response = yield client
      .mutate({
        mutation: query,
        variables:{
          email:data.email,
        }
      })
      .then(result => {
        return {
          success:true,
          payload:result
        };
      }
    ).
    catch(e => {
        return {
            success:false,
            payload:e
        };
    })
    
    return response;
      
}
