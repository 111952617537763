
import {  GETUSER_DATA_REQUESTED } from '../../constant/actionTypes';


export default function getUserDataAction(payload) {
  return {
    type: GETUSER_DATA_REQUESTED,
    payload,
  };
}





