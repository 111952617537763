
import {  LOGIN_REQUESTED,LOGIN_RESET_REQUESTED } from '../../constant/actionTypes';


export  function loginDetails(payload) {
  return {
    type: LOGIN_REQUESTED,
    payload,
  };
}

export  function resetLoginDetails() {
  return {
    type: LOGIN_RESET_REQUESTED,

  };
}





