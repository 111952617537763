
import {
    gql
  } from "@apollo/client";
import { privateClient as client } from '..';
import { commonErrorHandling } from '../Utils/index';


export default function* serviceAuthFieldsSaga(data) {
   

    const query = gql`
    query service_auths_by_serviceItem($service_id:Int){
        service_auths_by_service(service_id:$service_id) {
            id,
            meta_key,
            meta_value,
            default_meta_value,
          }
      }
    `;
    const response = yield client
      .query({
        query: query,
        variables:{
          service_id:data.service_id,
        }

      })
      .then(result => {
        return {
            success:true,
            payload:result
        };
      }
    ).
    catch(e => {
      commonErrorHandling( e)
      return {
          success:false,
          payload:e
      };
    })
    
    return response;
      
}
