import React from 'react';
import ErrorMessage from '../ErrorMessage';

function Checkbox({  name, label, placeholder, onChange, value, onBlur, touched, error  }) {
  return (
    <>
        <label className="checkbox bounce checkcs">
        <input name={name} value="1" type="checkbox"
            onChange={onChange}
            value={value}
            onBlur={onBlur}
        ></input>
        <svg viewBox="0 0 21 21">
            <polyline points="5 10.75 8.5 14.25 16 6"></polyline>
        </svg>
        <span>{label}</span>
    </label>
        <ErrorMessage touched={touched} error={error} />
    </>
  );
}



export default Checkbox;
