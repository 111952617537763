
import API from '../../constant'
import {
    ApolloClient,
    InMemoryCache,
    ApolloProvider,
    useQuery,
    gql,
    createHttpLink
  } from "@apollo/client";
import { privateClient as client } from '..';
import { commonErrorHandling } from '../Utils/index';

export default function* submitCatalogRuleSaga(data) {
   
  const query = gql`
  query catalog_rule_submit(
        $service_id: Int,
        $markup_type:String,
        $amount:Float,
        $is_never_exceed_msrp:Boolean!
        $is_map_based_price:Boolean!
        $map_markup_type:String
        $map_markup_amount:Float
        $is_enable_landed_cost:Boolean!
        $minimum_price:Float
        $fixed_dollar_addition:Float
        $price_cents_value:Int
        $is_include_shipping_cost:Boolean!
        $is_include_dorship_cost:Boolean!
        $is_prevent_price_below_wholesale:Boolean!
        $is_prevent_price_above_map:Boolean!
        $enable_category_wise:Boolean!,
        $catalogTiered:[CatalogTieredArray],
        $landed_cost_weight:[LandedCostWeight],
        $catagories:[CatalogCategoryArray]
      ){
    catalog_rule_submit(
        service_id: $service_id,
        markup_type:$markup_type,
        amount:$amount,
        is_never_exceed_msrp:$is_never_exceed_msrp
        is_map_based_price:$is_map_based_price
        map_markup_type:$map_markup_type
        map_markup_amount:$map_markup_amount
        is_enable_landed_cost:$is_enable_landed_cost
        minimum_price:$minimum_price
        fixed_dollar_addition:$fixed_dollar_addition
        price_cents_value:$price_cents_value
        is_include_shipping_cost:$is_include_shipping_cost
        is_include_dorship_cost:$is_include_dorship_cost
        is_prevent_price_below_wholesale:$is_prevent_price_below_wholesale
        is_prevent_price_above_map:$is_prevent_price_above_map
        enable_category_wise:$enable_category_wise
        catalogTiered:$catalogTiered,
        landed_cost_weight:$landed_cost_weight,
        catagories:$catagories
    ){
        message,
        success
      }
    
    }
  `;
  
    return yield client
      .query({
        query: query,
        variables:{
            service_id: data.service_id,
            markup_type: data.markup_type,
            amount: data.amount,
            is_never_exceed_msrp: data.is_never_exceed_msrp,
            is_map_based_price: data.is_map_based_price,
            map_markup_type: data.map_markup_type,
            map_markup_amount: data.map_markup_amount,
            is_enable_landed_cost: data.is_enable_landed_cost,
            minimum_price: data.minimum_price,
            fixed_dollar_addition: data.fixed_dollar_addition,
            price_cents_value: data.price_cents_value,
            is_include_shipping_cost: data.is_include_shipping_cost,
            is_include_dorship_cost: data.is_include_dorship_cost,
            is_prevent_price_below_wholesale: data.is_prevent_price_below_wholesale,
            is_prevent_price_above_map: data.is_prevent_price_above_map,
            enable_category_wise: data.enable_category_wise,
            catalogTiered: data.catalogTiered,
            landed_cost_weight: data.landed_cost_weight,
            catagories:data.catagories,
        },
        fetchPolicy: 'no-cache',

      })
      .then(result => {
        if(data.refreshCall){
          data.refreshCall()
        }
        return {
            success:true,
            payload:result
        };
      }
    ).
    catch(e => {
      
        commonErrorHandling( e)
        return {
            success:false,
            payload:e
        };
    });
      
}
