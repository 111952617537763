import React, { useEffect, useState } from 'react';
import placeholderImage from '../../assets/images/No-Image-Placeholder.svg';
import loadingGif from '../../assets/images/Rolling-1s-200px (1).gif';

function ImageBox({ src, ...props }) {
  const [imgSrc, setImgSrc] = useState(src);
  const [isLoaded, setIsloaded] = useState(false);
  useEffect(() => {
   
    setImgSrc(src);
}, [src]);
  const imageStyle = !isLoaded ? { display: "none" } : {};
  const onError = () => setImgSrc(placeholderImage)
  return (
      <>
    {!isLoaded &&  <img src={placeholderImage} onError={onError} {...props} />

    }
    <img src={imgSrc ? imgSrc : placeholderImage} style={imageStyle} onError={onError} {...props} onLoad={()=>setIsloaded(true)} />
    </>
  );
}



export default ImageBox;
