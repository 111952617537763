import React, { useState } from 'react';

function ErrorMessage({  error, touched  }) {

    return (
        <>
            <div className="error text-danger pt-1">
                { touched && error ? <><i class="fal fa-exclamation-triangle pe-1"></i>{error}</>  : null}
            </div>
        </>
    );
}



export default ErrorMessage;
