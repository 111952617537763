
import {
    gql
  } from "@apollo/client";
import { publicClient as client } from '..';
import { commonErrorHandling } from '../Utils/index';
export default function* settingItemSaga(data) {
   

    const query = gql`
    {
        settings{
            id,
            name,
            value
        }
      }
    `;
    const response = yield client
      .query({
        query: query,
      })
      .then(result => {
        return {
            success:true,
            payload:result
        };
      }
    ).
    catch(e => {
     
      commonErrorHandling( e)
        return {
            success:false,
            payload:e
        };
    })
    
    return response;
      
}
