import {

    LOGIN_REQUESTED,
    LOGIN_PROCESSING,
    LOGIN_SUCCESSED,
    LOGIN_FAILED,
    LOGOUT_REQUESTED,
    LOGIN_RESET_REQUESTED,

  } from '../../../constant/actionTypes';
   
  const initialState = {
    data: {},
    isProcessing: null,
    isSuccess: null,
    hasErrors: null,
  };
  
  export const loginStore = (state = initialState, action) => {
    switch (action.type) {
      case LOGOUT_REQUESTED: {
        return initialState;
      }
      case LOGIN_REQUESTED: {
        return {
          ...state,
          result: null,
          error: null,
          loading: true,
          isSuccess: false,
          requested: true,
        };
      }
      case LOGIN_PROCESSING: {
        return {
          ...state,
          result: null,
          error: null,
          loading: true,
          isSuccess: false,
          requested: false,
        };
      }
      case LOGIN_SUCCESSED: {
        return {
          result: action.data,
          error: null,
          loading: false,
          isSuccess: true,
          requested: false,
        };
      }
      case LOGIN_FAILED: {
        return {
          result: null,
          error: action.data,
          loading: false,
          isSuccess: false,
          requested: false,
        };
      }
      case LOGIN_RESET_REQUESTED: {
        return {
          ...state,
          error: "",
        };
      }
  
      default: {
        return {
          ...state,
        };
      }
    }
  };
  