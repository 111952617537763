import {

    SERVICE_DETAILS_REQUESTED,
    SERVICE_DETAILS_PROCESSING,
    SERVICE_DETAILS_SUCCESSED,
    SERVICE_DETAILS_FAILED,
   

  } from '../../../constant/actionTypes';
   
  const initialState = {
    data: {},
    isProcessing: null,
    isSuccess: null,
    hasErrors: null,
  };
  
  export const serviceDetailsStore= (state = initialState, action) => {
    switch (action.type) {

     
      case SERVICE_DETAILS_REQUESTED: {
        return {
          ...state,
          result: null,
          error: null,
          loading: true,
          isSuccess: false,
          requested: true,
        };
      }
      case SERVICE_DETAILS_PROCESSING: {
        return {
          ...state,
          result: null,
          error: null,
          loading: true,
          isSuccess: false,
          requested: false,
        };
      }
      case SERVICE_DETAILS_SUCCESSED: {
        return {
          result: action.data,
          error: null,
          loading: false,
          isSuccess: true,
          requested: false,
        };
      }
      case SERVICE_DETAILS_FAILED: {
        return {
          result: null,
          error: action.data,
          loading: false,
          isSuccess: false,
          requested: false,
        };
      }
  
      default: {
        return {
          ...state,
        };
      }
    }
  };
  