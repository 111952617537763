import React from 'react';
import ErrorMessage from '../ErrorMessage';

function InputBox({ type, name, label, placeholder, onChange, value, onBlur, touched, error,className,noIcon,disabled  }) {
  let iconClass;
  if(name === "name" || name === "username") iconClass = <i class="fa fa-user"></i>
  else if(name === "storename") iconClass = <i class="fa fa-shopping-bag"></i>
  else if(name === "email") iconClass = <i class="fa fa-envelope"></i>
  else if(name === "phone") iconClass = <i class="fa fa-phone-alt"></i>
  else if(name === "streetaddress") iconClass = <i class="fa fa-map-marker"></i>
  else if(name === "addressline2" || name === "city") iconClass = <i class="fa fa-map"></i>
  else if(name === "zipcode") iconClass = <i class="fa fa-terminal"></i>
  return (
    <>
        <label className="form-label">{label}</label>
        <div className="position-relative field-icon">
        <input 
          type={type}
          className={className?className:"form-control" }
          name={name}
          disabled={disabled}
          onChange={onChange}
          placeholder={placeholder}
          value={value}
          onBlur={onBlur}
        />
        {noIcon ? <></>
        
      :
         <span className="icon-form-field d-flex justify-content-center align-items-center">
         {iconClass}
          </span>
}
       </div>       
        <ErrorMessage touched={touched} error={error} />
    </>
  );
}




export default InputBox;
