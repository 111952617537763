import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import cloudImg from '../../../assets/images/cloud.png';
import FullLayout from '../../Layouts/FullLayout';
import LoginWrap from '../../../components/LoginWrap';
import MainLayout from '../../Layouts/index';

const RedirectPage = () => {
  const history = useHistory();


  const publicPath = ["/","/login", "/forgot-password", "/reset-password",'/about-us', '/register'];
  const AccessToken = localStorage.getItem("AccessToken");;
  useEffect(() => {
   
    const user = JSON.parse(localStorage.getItem("user"));
    if (history && !publicPath.includes(history.location.pathname)) {
      if (AccessToken === null || user.request_status != 2 ) {
        if(history.location.pathname == '/service-request' && user.request_status == 0){

        }else{
          history.push("/login");
        }
       
      }
    }
  });

  return (

    <>
    
    </>
  );
};

export default RedirectPage;
