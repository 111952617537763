import {
    gql
} from "@apollo/client";
import { privateClient as client } from '..';
import { commonErrorHandling } from '../Utils/index';

export default function* getCustomizeDetailsSaga(data) {
    const query = gql`  
    query get_customize_details_Item(
        $product_id:Int,
      ){
        getProductDetails(product_id: $product_id){
            product_id,
            upc,
            description,
            product_name,
            capacity,
            weight,
            manufacturer,
            manufacture_model_no
          }   
        }`;

    return yield client
        .query({
            query: query,
            variables: {
                product_id: data.product_id,
            },
            fetchPolicy: 'no-cache'
        })
        .then(result => {
            return {
                success: true,
                payload: result
            };
        }
        ).
        catch(e => {
            commonErrorHandling(e)
            return {
                success: false,
                payload: e
            };
        });
}