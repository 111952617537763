
import API from '../../constant'
import {
    ApolloClient,
    InMemoryCache,
    ApolloProvider,
    useQuery,
    gql,
    createHttpLink
  } from "@apollo/client";
import { privateClient as client } from '..';
import { commonErrorHandling } from '../Utils/index';

export default function* getCatalogRule(data) {

  const query = gql`
  query get_catalog_rule($service_id: Int){
        get_catalog_rule(service_id: $service_id){
          catalog_rule{
            id
            service_id
            markup_type
            amount
            is_never_exceed_msrp
            is_map_based_price
            map_markup_type
            map_markup_amount
            is_enable_landed_cost
            minimum_price
            fixed_dollar_addition
            price_cents_value
            is_include_shipping_cost
            is_include_dorship_cost
            is_prevent_price_below_wholesale
            is_prevent_price_above_map
            enable_category_wise
          
        },
        catalog_tiered{
            min_price
            max_price
            fixed_dollar
        },
        landed_cost_weight_tiered{

            weight_min
            weight_max
            markup_amount
            type
        },
        catagories{
          category_id,
          markup_type,
          amount,
        }
      }
    }
  `;
    const response = yield client
      .query({
        query: query,
        variables:{
            service_id: data.service_id,
        },
        fetchPolicy: 'no-cache',

      })
      .then(result => {
        return {
            success:true,
            payload:result
        };
      }
    ).
    catch(e => {
      
        commonErrorHandling( e)
        return {
            success:false,
            payload:e
        };
    })
    
    return response;
      
}
