import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Modal,Button } from 'react-bootstrap';

const ModalBox = ({

    onHide,show,body,title,className,size,hideHeader,centered
}) => {
  

  return (
    <>
      <Modal
      show={show}
      onHide={onHide}
      size={size}
      backdrop="static"
      className={className}
      aria-labelledby="contained-modal-title-vcenter"
      centered={centered?centered:false}
    >
  
     <Modal.Header >
     {!hideHeader &&<h3>{title} </h3> }
     
      <div class="modal-close "  aria-label="Close" onClick={()=>onHide()}><i class="fa fa-times"></i></div>
      </Modal.Header>
  
      <Modal.Body>
        {body()}
      </Modal.Body>
    </Modal>
    </>
  );
};

export default ModalBox;
