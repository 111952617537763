import {

  FORGOT_PASSWORD_REQUESTED,
  FORGOT_PASSWORD_PROCESSING,
  FORGOT_PASSWORD_SUCCESSED,
  FORGOT_PASSWORD_FAILED,


} from '../../../constant/actionTypes';
 
const initialState = {
  data: {},
  isProcessing: null,
  isSuccess: null,
  hasErrors: null,
};

export const forgotPasswordStore = (state = initialState, action) => {
  switch (action.type) {

    case FORGOT_PASSWORD_REQUESTED: {
      return {
        ...state,
        result: null,
        error: null,
        loading: true,
        isSuccess: false,
        requested: true,
      };
    }
    case FORGOT_PASSWORD_PROCESSING: {
      return {
        ...state,
        result: null,
        error: null,
        loading: true,
        isSuccess: false,
        requested: false,
      };
    }
    case FORGOT_PASSWORD_SUCCESSED: {
      return {
        result: action.data,
        error: null,
        loading: false,
        isSuccess: true,
        requested: false,
      };
    }
    case FORGOT_PASSWORD_FAILED: {
      return {
        result: null,
        error: action.data,
        loading: false,
        isSuccess: false,
        requested: false,
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
};
