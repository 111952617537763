
import {userLogout} from "../../action/Auth/logout";
import { store } from '../../redux/store/index';

/* Error handling */
export function commonErrorHandling(response) {
  
    const networkError = response.networkError?.result;
    const graphQLErrors = response.graphQLErrors;
    
    if(networkError && networkError?.errors[0]?.message == 'Context creation failed: invalid token'){
        store.dispatch(userLogout());
    }

    if(networkError && networkError?.errors[0]?.message == "Context creation failed: jwt expired"){
        store.dispatch(userLogout());
    }
    
    if(graphQLErrors && graphQLErrors[0]?.message == 'You are not authenticated'){
        store.dispatch(userLogout());
    }

    if(networkError &&  networkError?.errors[0]?.message == 'Context creation failed: jwt malformed'){
        store.dispatch(userLogout());
    }
    
}