
import API from '../../constant'
import {
    ApolloClient,
    InMemoryCache,
    ApolloProvider,
    useQuery,
    gql,
    createHttpLink
  } from "@apollo/client";
import { privateClient as client } from '..';
import { commonErrorHandling } from '../Utils/index';

export default function* advanceSearchAttributesSaga(data) {
 
  const query = gql`

  {
  	advanceSearchAttributes{
				id,
      	attribute_name,
      	attribute_type,
      	attribute_code
    
    }
}

  `;
    const response = yield client
      .query({
        query: query
      })
      .then(result => {
        return {
            success:true,
            payload:result
        };
      }
    ).
    catch(e => {
      
        commonErrorHandling( e)
        return {
            success:false,
            payload:e
        };
    })
    
    return response;
      
}
