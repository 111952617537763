
import {
    gql
  } from "@apollo/client";
  import { privateClient as client } from '..';
  import { commonErrorHandling } from '../Utils/index';
  
  
  export default function* removeProductImageSaga(data) {

    const query = gql`
    mutation removeProductImageItem($product_id:Int,$image_id:Int){  
        removeProductImage(product_id:$product_id,image_id:$image_id){
            status,
            message
          }
  }
    `;
  
    return yield client
      .mutate({
        mutation: query,
        variables:{
            product_id:data.product_id,
            image_id:data.image_id
        }
      })
      .then(result => {
        
        return {
          success:true,
          payload:result
        };
      }
    ).
    catch(e => {
      commonErrorHandling( e)
        return {
            success:false,
            payload:e
        };
    });
      
  }
  