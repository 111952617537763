
import {
    gql
  } from "@apollo/client";
import { privateClient as client } from '..';
import API from '../../constant'
import axios from 'axios';

export default function* profileUpload(loginData,callback) {

  var axios = require('axios');
  var FormData = require('form-data');
  var fs = require('fs');
  var data = new FormData();
  data.append('operations', '{"query":"mutation profileUploadItem($file:Upload!){\\r\\n  \\tprofileUpload(file:$file){\\r\\n    status,\\r\\n    url\\r\\n}\\r\\n}"}');
  data.append('map', '{"0": ["variables.file"]}');
  data.append('0', loginData.file);
  const token = localStorage.getItem('AccessToken');
  const xAuthApi = API.xAuthToken;
  var config = {
    method: 'post',
    url: API.url,
    headers: { 
      'Authorization': token, 
      "x-auth-token":xAuthApi,
      "Content-Type": "multipart/form-data"
    },
    data : data
  };
  
  const response = yield axios(config).then(function (response) {
    console.log(JSON.stringify(response.data));
    
    return response.data;
  })
  .catch(function (error) {
   return error;
  });
    
    return response;
      
}
