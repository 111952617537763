
import {
    gql
  } from "@apollo/client";
  import { privateClient as client } from '..';
  import { commonErrorHandling } from '../Utils/index';
  
  
  export default function* crowServiceIntegrationSaga(data) {
  
    const query = gql`
        mutation submitCrowServiceItem($service_id:Int, $first_name:String! ,$last_name:String!,$email:String!,$mobile:String!){
            submitCrowService(service_id:$service_id, first_name:$first_name, last_name:$last_name, email:$email, mobile:$mobile) {
                message
            }
        }
    `;
  
    return yield client
      .mutate({
        mutation: query,
        variables:{
          service_id:data.service_id,
          first_name: data.firstName,
          last_name: data.lastName,
          email: data.email,
          mobile: data.phone
        }
      })
      .then(result => {
        return {
          success:true,
          payload:result
        };
      }
    ).
    catch(e => {
      commonErrorHandling( e)
        return {
            success:false,
            payload:e
        };
    });
      
  }
  