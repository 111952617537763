
import {
    gql
  } from "@apollo/client";
  import { privateClient as client } from '..';
  import { commonErrorHandling } from '../Utils/index';
  
  
  export default function* updateServiceStatusSaga(data) {

    const query = gql`
    mutation update_service_status($status:Int,$service_id:Int){  
        updateServiceStatus(status:$status, service_id:$service_id){
            status,
            message
          }
  }
    `;
  
    return yield client
      .mutate({
        mutation: query,
        variables:{
            status:data.status,
            service_id:data.service_id
        }
      })
      .then(result => {
        
        return {
          success:true,
          payload:result
        };
      }
    ).
    catch(e => {
      commonErrorHandling( e)
        return {
            success:false,
            payload:e
        };
    });
      
  }
  