import React, { useEffect, useState } from 'react';
import cloudImg from '../../../assets/images/cloud.png';
import FullLayout from '../../Layouts/FullLayout';
import LoginWrap from '../../../components/LoginWrap';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import InputBox from '../../../components/Form/InputBox';
import PasswordInputBox from '../../../components/Form/PasswordInputBox';
import Checkbox from '../../../components/Form/Checkbox';
import {loginDetails} from '../../../action/Auth/login';
import { compose } from 'redux';
import { connect } from 'react-redux';
import ErrorMessage from '../../../components/Form/ErrorMessage';
import { Alert } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import { useParallax , Parallax } from 'react-scroll-parallax';

import '../../../assets/homePage/c584631aac3102923a80b2ea67c879c5.css';
import '../../../assets/homePage/febd65cce2e0af2e9000e8d9cfaf32c5.css'
import '../../../assets/homePage/homepage.css';
import '../../../assets/homePage/font.css';
import { Link } from 'react-router-dom';

const Aboutus = ({loginDetails,loginStore}) => {

  const history = useHistory();

  useEffect(() => {
    document.title = 'Cloud Connect - Distributor Feeds by Weapon Depot';
  }, []);

  function redirectTOLogin(){
    history.push("/login");
  }

  function goToAboutUs(){
    history.push("/about-us");
  }

  function goToprivcyPolicy(){
    window.location.href = "https://www.weapondepot.com/weapon-depot-privacy-policy/";
  }

  return (
  
    <body className="brz">
        <div className='brz-root__container brz-reset-all'>
            <section data-uid="ueivhixnnkamwytthspolftzlgtszczymodv" id="ueivhixnnkamwytthspolftzlgtszczymodv" className="brz-section brz-css-vugti brz-css-fzjkd">
                <div className="brz-section__content brz-section--boxed brz-css-mwhob brz-css-sqsrr" data-custom-id="zdnfsxgiwpnitrsqukosgponckzvudqzbpuc">
                    <div className="brz-bg">
                        <div className="brz-bg-color"></div>
                    </div>
                    <div className="brz-container brz-css-pbhem">
                        <div className="brz-row__container brz-css-fgwvm" data-custom-id="blsklzmdiizssknodddbbmyyoinpofjtqqgf">
                            <div className="brz-row brz-css-vxblm brz-css-twejo">
                                <div className="brz-columns brz-css-dynog brz-css-xqjbh" data-custom-id="hvdormvyyzhmkrcqxgcgitgqvpmcbqnrnoyp">
                                    <div className="brz-bg">
                                        <div className="brz-bg-color"></div>
                                    </div>
                                    <div className="brz-column__items brz-css-ecksy brz-css-jugzp">
                                        <div className="brz-css-ouobs brz-css-xzspy brz-wrapper">
                                            <div className="brz-image brz-css-kfgvz">
                                                <picture className="brz-picture brz-d-block brz-p-relative brz-css-bwlsg" data-custom-id="xoebioevncwdstaufovdbeyqqkhslyrvgdte">
                                                    <source srcset="assets/img/b4c610c8d9007deda4747fa38c5d0124.png 1x, assets/img/7eed853163742f6ff009af52ff26e8ba.png 2x" media="(min-width: 992px)" />
                                                    <source srcset="assets/img/e5d9ba31fdb7e4837c261062b97ff236.png 1x, assets/img/161ea182b5a3faabac4456de26e3105d.png 2x" media="(min-width: 768px)" />
                                                    <img className="brz-img" srcset="assets/img/753f8b29c69201c3320abcb3f26c2921.png 1x, assets/img/171293ae692a038cff0b0b2fe45f949c.png 2x" src="assets/img/b4c610c8d9007deda4747fa38c5d0124.png"  draggable="false" loading="lazy" />
                                                </picture>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="brz-columns brz-css-dynog brz-css-qdygj main-text" data-custom-id="oncawotjufeuqiofsqfmyqemzosqrfgddurc">
                                    <div className="brz-column__items brz-css-ecksy brz-css-ryvlv">
                                        <div className="brz-css-ouobs brz-wrapper">
                                            <div className="brz-rich-text brz-rich-text__custom brz-css-rmcic" data-custom-id="esxmgmdlctipqkfchbvmgnbdywhetdmqfhor">
                                                <div className="white-space-normal">
                                                    <p className="brz-css-xbqfe" data-uniq-id="jdzbw" data-generated-css="brz-css-lxjkp"><strong style={{color: "rgb(249, 157, 0)"}}>Cloud Connect</strong></p>
                                                    <p className="brz-css-vlrgl" data-uniq-id="eceeu" data-generated-css="brz-css-xcjoc"><em className="brz-cp-color7">Powered by </em><a href="https://www.weapondepot.com" target="_blank"  className="brz-cp-color7 link--external" data-brz-link-type="external"><em>WeaponDepot.com</em></a><em className="brz-cp-color7"> </em></p>
                                                </div>
                                            </div>
                                          
                                        </div>
                                       
                                    </div>
                                </div>
                               
                            </div>
                            <div className="brz-columns brz-css-dynog brz-css-qdygj lohin-htn" >
                                    <button onClick={() => { redirectTOLogin()}} class="brz-btn brz-btn-submit brz-css-wmnit brz-css-rhvdx" data-custom-id="eykylnpiqtmecbwaxhypadsiwvlxrvrwwcyc"><span class="brz-span brz-text__editor">Login</span></button>
                            </div>
                           
                        </div>
                        
                    </div>
                </div>
            </section>
            <section data-uid="coivtdumixpjviidlnadxdzyiccjgmmsvkte" id="coivtdumixpjviidlnadxdzyiccjgmmsvkte" className="brz-section brz-css-vugti new-secontion-mb">
                <div className="brz-section__content brz-section--boxed brz-css-mwhob brz-css-jakdx" data-custom-id="snqwmrictscnwkevvcgydzzyyqfanlmfoqew">
                    <div className="brz-container brz-css-pbhem">
                        <div>
                            <h1 className='bold mb-3'>1. What is Cloud Connect?</h1>
                        </div>
                        <div>
                            <p className='mb-3 f-18'>Cloud Connect is a secure cloud-based platform that allows companies to easily connect their dropship suppliers with WeaponDepot.com online marketplace. Cloud Connect provides listing synchronization, inventory control, customizable pricing rules, and dropship automation for sellers looking to extend existing applications or build new ones that span across multiple sales channels. It is designed to streamline development processes through Open Source client libraries as well as provide easy access for end users with support for web browsers and mobile devices. </p>
                        </div>
                    </div>
                    <div className="brz-container brz-css-pbhem mt-3">
                        <div>
                            <h1 className='bold mb-3'>2. What are the benefits of using Cloud Connect?</h1>
                        </div>
                        <div>
                            <p className='mb-3 f-18'>The benefits of using Cloud Connect include: increased efficiency and speed of dropship automation processes, secure access between dropship suppliers and WeaponDepot.com marketplace, customizable pricing rules for different suppliers, real-time visibility into inventory levels across multiple sales channels, and Open Source client libraries to streamline development processes. </p>
                        </div>
                    </div>

                    <div className="brz-container brz-css-pbhem mt-3">
                        <div>
                            <h1 className='bold mb-3'>3. How do I get started with Cloud Connect?</h1>
                        </div>
                        <div>
                            <p className='mb-3 f-18'>Getting started with Cloud Connect is easy and straightforward. First, you will need to register for a free seller account on the Cloud Connect website. Once you've registered, you can connect your dropship suppliers to WeaponDepot.com marketplace and then customize pricing rules for different suppliers directly from within the application. Finally, you can access real-time inventory levels across all of your sales channels through the Cloud Connect dashboard. With these tools in place, it's time to start automating your dropship processes and increasing efficiency! </p>
                        </div>
                    </div>

                    <div className="brz-container brz-css-pbhem mt-3">
                        <div>
                            <h1 className='bold mb-3'>4. What are some of the best practices for using Cloud Connect?</h1>
                        </div>
                        <div>
                            <p className='mb-3 f-18'>Some of the best practices for using Cloud Connect include: offering competitive prices across all sales channels, connecting reliable dropship suppliers, streamlining development processes with Open Source client libraries, utilizing a secure connection between dropship suppliers and WeaponDepot.com marketplace, and monitoring inventory levels in real time. Additionally, it's important to make sure that your store is SEO optimized so that customers can easily find your products online. Finally, regularly review customer feedback and address any purchase issues in a timely manner to ensure customer satisfaction. </p>
                        </div>
                    </div>
                    <div className="brz-container brz-css-pbhem mt-3">
                        <div>
                            <h1 className='bold mb-3'>5. How do I troubleshoot Cloud Connect issues?</h1>
                        </div>
                        <div>
                            <p className='mb-3 f-18'>If you experience any issues with Cloud Connect, the first step is to troubleshoot by visiting the documentation page on the Cloud Connect website. Here, you'll find a list of frequently asked questions and their respective answers that can help pinpoint and resolve your issue quickly. If you still need additional help, then you can email our support team at hello@weapondepot.com or call 844-292-8756. </p>
                        </div>
                    </div>
                    <div className="brz-container brz-css-pbhem mt-3">
                        <div>
                            <h1 className='bold mb-3'>6. How do I contact Cloud Connect support?</h1>
                        </div>
                        <div>
                            <p className='mb-3 f-18'>If you need assistance with Cloud Connect, you can email our support team at hello@weapondepot.com or call 844-292-8756. Additionally, visit the documentation page on the Cloud Connect website for a list of frequently asked questions and their respective answers that may help troubleshoot your issue quickly. </p>
                        </div>
                    </div>
                    <div className="brz-container brz-css-pbhem mt-3">
                        <div>
                            <h1 className='bold mb-3'>7. What are the Cloud Connect system requirements?</h1>
                        </div>
                        <div>
                            <p className='mb-3 f-18'>The Cloud Connect system requirements are as follows: an active seller account with WeaponDepot.com, and at least one active dealer account with one of our integrated suppliers.  It's important that any dropship suppliers you connect with have their own system requirements in place in order for the integration to work correctly. </p>
                        </div>
                    </div>
                    <div className="brz-container brz-css-pbhem mt-3">
                        <div>
                            <h1 className='bold mb-3'>8. Is there a Cloud Connect user guide?</h1>
                        </div>
                        <div>
                            <p className='mb-3 f-18'>Yes, there is a Cloud Connect user guide available on the website. It covers everything from setting up your dropship suppliers to customizing pricing rules and monitoring inventory levels in real time. The user guide also includes troubleshooting tips, best practices for using Cloud Connect, and contact information for support.</p>
                        </div>
                    </div>
                    
                </div>
                
            </section>
            <section data-uid="coivtdumixpjviidlnadxdzyiccjgmmsvkte" id="coivtdumixpjviidlnadxdzyiccjgmmsvkte" className="brz-section brz-css-vugti">
                <div className="brz-section__content brz-section--boxed brz-css-mwhob brz-css-jakdx" data-custom-id="snqwmrictscnwkevvcgydzzyyqfanlmfoqew">
                    <div className="brz-bg">
                        <div className="brz-bg-color"></div>
                    </div>
                    <div className="brz-container brz-css-pbhem">
                        <div className="brz-row__container brz-css-fgwvm brz-css-biqqb" data-custom-id="dgwbyfldkhaanifsnwjsfzuqmjzjysydcsxj">
                            <div className="brz-row brz-css-vxblm brz-css-twejo">
                                <div className="brz-columns brz-css-dynog brz-css-knzat" data-custom-id="ydoaucpslmclcoekmnxfaphrqpogftxhrarc">
                                    <div className="brz-column__items brz-css-ecksy brz-css-sojug">
                                        <div className="brz-css-ouobs brz-css-njfvk brz-wrapper">
                                            <div className="brz-rich-text brz-rich-text__custom brz-css-rmcic" data-custom-id="urwgnegvebbnahwzgkejonxkkveofybefddi">
                                                <div>
                                                    <p className="brz-css-ajpoj" data-generated-css="brz-css-uamqg" data-uniq-id="lkzuw"><span className="brz-cp-color4">Copyright </span><span style={{color: "rgb(253, 253, 253)"}}>&#xA9; {(new Date().getFullYear())} Weapon Depot</span></p>
                                                </div>
                                                <div>
                                                    <p className="brz-css-ajpoj url-view cursor-pointer" onClick={()=>goToAboutUs()} data-generated-css="brz-css-uamqg" data-uniq-id="lkzuw">
                                                    <Link to='/about-us'><span className="brz-cp-color4">About us </span></Link> 
                                                        </p>
                                                </div>
                                                <div>
                                                    <p className="brz-css-ajpoj url-view cursor-pointer" onClick={()=>goToAboutUs()} data-generated-css="brz-css-uamqg" data-uniq-id="lkzuw">
                                                    <Link to='/faqs'><span className="brz-cp-color4">FAQs </span></Link> 
                                                        </p>
                                                </div>
                                                <div>
                                                    <p className="brz-css-ajpoj url-view cursor-pointer"  data-generated-css="brz-css-uamqg" data-uniq-id="lkzuw">
                                                      
                                                        <a href='https://www.weapondepot.com/weapon-depot-privacy-policy/'><span className="brz-cp-color4">Privacy policy </span></a> 
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="brz-columns brz-css-dynog brz-css-mwvns" data-custom-id="julbyhtxpnvltvrlrwentibemdticctprovv">
                                    <div className="brz-column__items brz-css-ecksy">
                                        <div className="brz-css-ouobs brz-wrapper">
                                            <div className="brz-image brz-css-sleox">
                                                <picture className="brz-picture brz-d-block brz-p-relative brz-css-ecexl" data-custom-id="bqlvsolbgmtuuoswjejckjifqpmrtdmbhcjx">
                                                    <source srcset="assets/img/54ce783012ec7d0a1489f7f648c993a9.png 1x, assets/img/9f6ba64d532e12b26882810ae053fb22.png 2x" media="(min-width: 992px)" />
                                                    <source srcset="assets/img/510611ef3d045170a216eaf59a460666.png 1x, assets/img/2cf27d114e395d44f038164d4fbef438.png 2x" media="(min-width: 768px)" /> 
                                                    <img className="brz-img" srcset="assets/img/b748850b5438cfced939e16e90ccb72f.png 1x, assets/img/9dbe86270799300d3e07088e0ea3b6f2.png 2x" src="assets/img/54ce783012ec7d0a1489f7f648c993a9.png" alt draggable="false" loading="lazy" />
                                                </picture>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </div>
    </body>
  );
};


const mapStateToProps = state => {
    return {
        loginStore: state.loginStore,
    };
  };
  
  const mapDispatchToProps = {
    loginDetails
 
  };
  
  export default compose(
    connect(mapStateToProps, mapDispatchToProps),
  )(Aboutus);