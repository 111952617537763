import React, { lazy, useEffect } from 'react';
import { Route, Redirect, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';


const App = lazy(() => import('./App'));

const PrivateRoute = ({
  component: Component,
  ...rest
}) => {
  const history = useHistory();
 
  return <Route render={() => <Component {...rest} />} />;
};


const mapStateToProps = state => {
  return {

  };
};

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
