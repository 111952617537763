
import {
    gql
  } from "@apollo/client";
import { privateClient as client } from '..';
import { commonErrorHandling } from '../Utils/index';
export default function* attributefilterlistSaga(data) {
   
 
    const query = gql`
    query attributesAllItem($service_id:Int,$type:Int,$attribute_id:Int,$pageNo:Int,$term:String){
        attributesAll(service_id:$service_id,type:$type,attribute_id:$attribute_id,pageNo:$pageNo,term:$term){
          id,
          attribute_code,
          attribute_name,
          values,
          totalCount
        }
      }
    `;
    const response = yield client
      .query({
        query: query,
        variables:{
          service_id:data.service_id,
          type:data.type,
          attribute_id:data.attribute_id,
          pageNo:data.pageNo,
          term:data.term?data.term:""
          },
          fetchPolicy: 'no-cache',

      })
      .then(result => {
        return {
            success:true,
            payload:result
        };
      }
    ).
    catch(e => {
     
      commonErrorHandling( e)
        return {
            success:false,
            payload:e
        };
    })
    
    return response;
      
}
