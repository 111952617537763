
import {
    gql
  } from "@apollo/client";
  import { privateClient as client } from '..';
  import { commonErrorHandling } from '../Utils/index';
  
  
  export default function* customizeSaga(data) {

    const query = gql`
    mutation updateProductItem($product_id:Int,$upc:String,$product_name:String,$description:String,$manufacture_model_no:String,$manufacturer:String,$weight:String,$capacity:String){  
        updateProduct(product_id:$product_id,upc:$upc,product_name:$product_name,description:$description,manufacture_model_no:$manufacture_model_no,manufacturer:$manufacturer,weight:$weight,capacity:$capacity){
          status,
          message          
          }  
  }
    `;
  
    return yield client
      .mutate({
        mutation: query,
        variables:{
            product_id:data.product_id,
            upc:data.upc,
            product_name:data.product_name,
            description:data.description,
            manufacturer:data.manufacturer,
            manufacture_model_no:data.manufacture_model_no,
            weight:data.weight,
            capacity:data.capacity
        }
      })
      .then(result => {
        
        return {
          success:true,
          payload:result
        };
      }
    ).
    catch(e => {
      commonErrorHandling( e)
        return {
            success:false,
            payload:e
        };
    });
      
  }
  