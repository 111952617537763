import React, { useEffect, useState,useRef } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import profileImage from '../../../assets/images/profile-image.jpg';
import cloud from '../../../assets/images/cloud.png';
import {userLogout,resetAllState,profileUpload} from '../../../action/Auth/logout';
import getUserData from '../../../action/User/getUserData';
import ImageBox from '../../../components/ImageBox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRefresh } from '@fortawesome/free-solid-svg-icons'
import { useLocation,matchPath  } from 'react-router-dom'
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive'
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import ModalBox from '../../pages/Supplier/Componenets/ModalBox';
import ReactCrop, {
    centerCrop,
    makeAspectCrop,
    Crop,
    PixelCrop,
  } from 'react-image-crop'
import { imgPreview } from './imgPreview'
import { useDebounceEffect } from './useDebounceEffect'
import userImage from '../../../assets/images/user.png'
import 'react-image-crop/dist/ReactCrop.css'
import { Button } from 'react-bootstrap';
import Croppie from 'croppie';
import 'croppie/croppie.css';


const collapsed = 'collapsed';
const mainSideBarclassNameName ='main-sidebar not-collapsed trans3s'

function SidebarContainer({profileUpload,getUserDataStore,getUserData,profileUploadStore,sliderVisible, userLogout,logoutStore,resetAllState,children,setSliderVisible}) {
 
    const user = JSON.parse(localStorage.getItem("user"));
    const liRef = useRef(null);

    const location = useLocation();
    const history = useHistory();
    const previewCanvasRef = useRef(null)
    const [scale,setScale] = useState(1);
    const [crop, setCrop] = useState();
    const [currentImage, setCurrentImage] = useState('');
    const [aspect, setAspect] = useState(1 / 1);
    const [completedCrop, setCompletedCrop] = useState();
    const [showModal, setShowModal] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [isUploadView,setIsUploadView] = useState(false);
    const [isUploaded,setIsUploaded] = useState(false);
    const [croppie, setCroppie] = React.useState(null);


    
    const [uploadTitle,setUploadTitle] = useState('Change profile picture');
    const imgRef = useRef(null)
    const onHide = () =>{
        setShowModal(false);
        setIsUploadView(false);
        setSelectedFile(null);
        setUploadTitle('Change profile picture');
        setIsUploaded(false)
      }
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    function logout() {
        userLogout();
        
    }

  

    useDebounceEffect(
        async () => {
          if (
            completedCrop?.width &&
            completedCrop?.height &&
            imgRef.current
          ) {
            // We use canvasPreview as it's much faster than imgPreview.
          let data =  await imgPreview(
              imgRef.current,
              completedCrop,
              scale,
              0,
            )
            
            setCurrentImage(data);
           
          }
        },
        100,
        [completedCrop,scale],
      )

  

    function centerAspectCrop(
        mediaWidth,
        mediaHeight,
        aspect,
      ) {
      
        return centerCrop(
          makeAspectCrop(
            {
              unit: '%',
              width:40,
            },
            aspect,
            mediaWidth,
            mediaHeight,
          ),
          mediaWidth,
          mediaHeight,
        )
      }

    useEffect(() => {
        getUserData();
       
    }, []);

   

 

  

    const openFileUpload = () =>{
        setShowModal(true);
    }

    const submitFileUpload = () => {
        
  
      if (croppie !== null) {
          croppie
              .result({
                  type: 'base64',
                  size: {
                      width: 480,
                      height: 480,
                  },
              })
              .then((blob) => {
                        axios({
                          method: 'get',
                          url: blob, 
                          responseType: 'blob'
                      }).then(function(response){
                          var reader = new FileReader();
                          reader.readAsDataURL(response.data); 
                          reader.onloadend = function() {
                            
                              var file = new File([response.data], "my_image.png",{type:"image/png", lastModified:new Date().getTime()})
                              profileUpload({file:file},
                                function() {
                                    getUserData();
                                  // setShowModal(false);
                                  setIsUploadView(false);
                                  setIsUploaded(true);
                                  setUploadTitle("That's a keeper!")
                                  setSelectedFile(null);
                                })
                          }
                  
                      })
              });
      }

       

    
        

     
    }

    function removeImage(){
      profileUpload({file:null},
        function() {
            getUserData();
            //setShowModal(false);
        })
    }

    const onFileChange = (event) =>{
        if (event.target.files && event.target.files.length > 0) {
            
            setIsUploadView(true);
            setUploadTitle('Upload Profile')
            const image = event.target.files[0];
            setSelectedFile(image);
            const el = document.getElementById('image-helper');
            console.log(el);
            const reader = new FileReader();
            reader.readAsDataURL(image);
            if (el) {
                console.log('EL', el);
                let croppieInstance = null;
                if(document.getElementById("image-helper").classList.contains("croppie-container")){
                  croppieInstance = croppie;
                }else{
                   croppieInstance = new Croppie(el, {
                    enableExif: true,
                    
                    viewport: {
                        height: 200,
                        width: 200,
                        type: 'circle' 
                    },
                    boundary: {
                        height: 280,
                        width: 400,
                    },
                  });
                }

              
              
           

                   

                
                
                
            
                reader.onload = () => {
                    // @ts-ignore
                    setUploadTitle("What's your best angel?")
                    croppieInstance.bind({ url: reader.result });
                };
                // croppieInstance.bind({
                //     url: image,
                // });
                setCroppie(croppieInstance);
            }

            
        }

       // setSelectedFile(e.target.files[0])
    }

    function onImageLoad(e) {
        if (aspect) {
          const { width, height } = e.currentTarget
          setCrop(centerAspectCrop(width-50, height-50, aspect))
        }
      }

   
    
    const onScroll = (e) => {
      const delta = e.deltaY * -0.01;
      const newScale = scale + delta/10;
  
      
      if(newScale != 0){
        setScale(newScale);
      }
      
      console.log(delta,newScale);
    };


    const changeScale = (e) => {

      console.log(e.targe.value);
    }

    const body =  () => {
        return (
          <>
          {!isUploadView &&
            <div className='mb-3'>
           
            
              <div className='d-flex justify-content-center'>
                
             
                  <img  src={getUserDataStore?.result?.profile_image?getUserDataStore?.result?.profile_image:userImage} alt="user" className="image-profile-rounded-1 img-fluid rounded-circle" >
                  
                  </img>
                  
              </div>
              {isUploaded?
                  <>
                  <div className='d-flex justify-content-center mt-4 pt-2'>
                      <button type="button" class="btn btn-primary image-upload" onClick={()=>{setIsUploadView(false);setIsUploaded(false);setUploadTitle('Change profile picture');setShowModal(false)}}>
                    Love it</button>
                </div>   
                <div className='d-flex justify-content-center mt-3'>
                      <a class="text-primary-ls" onClick={()=>{setUploadTitle('Upload Profile');setIsUploaded(false);}}>
                     Try Another</a>
                </div>  

                </>
            
              :
              <>
              <div className='d-flex justify-content-center mt-4 pt-2'>
                

                    <div class="file-input">
                          <input
                            type="file"
                            name="file-input"
                          
                            id="file-input"
                            onChange={onFileChange}
                            class="file-input__input"
                          />
                          <label class="file-input__label" for="file-input">
                            <svg
                              aria-hidden="true"
                              focusable="false"
                              data-prefix="fas"
                              data-icon="upload"
                              class="svg-inline--fa fa-upload fa-w-16"
                              role="img"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 512 512"
                            >
                              <path
                                fill="currentColor"
                                d="M296 384h-80c-13.3 0-24-10.7-24-24V192h-87.7c-17.8 0-26.7-21.5-14.1-34.1L242.3 5.7c7.5-7.5 19.8-7.5 27.3 0l152.2 152.2c12.6 12.6 3.7 34.1-14.1 34.1H320v168c0 13.3-10.7 24-24 24zm216-8v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h136v8c0 30.9 25.1 56 56 56h80c30.9 0 56-25.1 56-56v-8h136c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"
                              ></path>
                            </svg>
                            <span>Upload Image</span>
                          </label>
                        </div>
              </div>   
               {  getUserDataStore?.isSuccess  &&  getUserDataStore?.result?.profile_image &&
                <div className='d-flex justify-content-center mt-2 pt-2'>
                  <button className='btn btn-remove' onClick={()=>removeImage()}>
                    <i className='fas fa-trash' />
                    Remove Image</button>

                </div>
                }
              </>
              }           

            </div>

          }
            <>
            <div class="mb-3" style={isUploadView?{display:'block'}:{display:'none'}}>
            
          
             
                     <div>
                      <div id="image-helper" />
                      </div>
                   
                   
                     <div>
                 
                </div>
            </div>
            {isUploadView &&
            <div className="form-field">
                <button  type="submit"
                    onClick={submitFileUpload}
                    className='btn btn-primary me-2'
                >   
                    Upload 
                </button>

                <button  
                    onClick={onHide}
                    className='btn btn-secondary'
                >   
                
                    Cancel 
                </button>
            </div>
              }
            </>
          
          </>
        )
      }
  

    function isActivePage(parent){
     
       
        let val = children.find((item) =>{
            const match = matchPath(location.pathname, {
                path: item.path,
                exact: item.exact,
                strict: true
            });
            if(match){
                return item.parent
            } 
        });
      
        if(val?.parent== parent){
            return true;
        }else{
            return false;
        }
    }

    

    useEffect(() => {
   
        if(logoutStore?.isSuccess){
            resetAllState()
            history.push("/login");
        }
    }, [logoutStore]);
    
    return (
        <aside className={sliderVisible?mainSideBarclassNameName+' '+collapsed:mainSideBarclassNameName}>
            <div className="sidebar-content">
                <div className="site-info">
                    <a >
                        <ImageBox width="40" src={cloud} alt="Cloud" className="img-fluid" />
                        
                    </a>
                   
                    <button  className='navbar-close-icon d-md-none d-lg-none d-sm-none d-block'
                     onClick={()=>setSliderVisible(true)}
                    >&times;</button>
                    
                </div>
                <div className="sidebar-user-penel mt-4 text-center text-white user-profile-image-wrap">
                    <div className="user-profile-image d-flex justify-content-center">

                  
                            <img  src={getUserDataStore?.result?.profile_image?getUserDataStore?.result?.profile_image:userImage} alt="user" className="image-profile-rounded img-fluid rounded-circle" >
                            
                            </img>
                             
                              
                        <i class="far fa-address-card" onClick={openFileUpload}></i>
                    </div>
                    <p className="user-name mb-0 mt-2">{getUserDataStore?.result?.name}</p>
                    <p className="user-email">{getUserDataStore?.result?.email}</p>
                </div>
                <div className="sidebar-navigation">
                    
                    <ul className="sidebar-nav">
                        {children && children.length > 0 && children?.map((item) =>{
                                    if(item.onMenu){
                                        return(
                                        <li  key={item.key}
                                        className={isActivePage(item.parent)?"active-page":""} onClick={()=>{if(isTabletOrMobile)setSliderVisible(true)}}>
                                            <Link to={item.path}><i className={item.icon+" fal me-2"}></i> <span className="menu-title">{item.title}</span></Link>
                                       
                                        </li>
                                        )
                                    }
                                }
                            )
                        }
                    </ul>

                    <ul className="sidebar-nav">
                    
                        <li onClick={()=> logout()}>
                            <a ><i className="fal fa-sign-out me-2"></i> <span className="menu-title">
                                    Logout</span></a>

                        </li>
                    </ul>
                </div>
            </div>
            <ModalBox
                className={'moreInfoModal profile-upload'}
                onHide={onHide}
                show={showModal}
                body={body}
                size={"md"}
                title={uploadTitle}
            />
        </aside>
    );
}




  const mapStateToProps = state => {
    return {
        logoutStore:state.logoutStore,
        profileUploadStore:state.profileUpload,
        getUserDataStore:state.getUserDataStore
    };
  };

  const mapDispatchToProps = {
    userLogout,
    resetAllState,
    profileUpload,
    getUserData
    
 
  };
  
  export default compose(
    connect(mapStateToProps, mapDispatchToProps),
  )(SidebarContainer);