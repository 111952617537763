
import {
    gql
  } from "@apollo/client";
import { publicClient as client } from '..';



export default function* loginSaga(loginData) {
 
    const loginQuery = gql`
        mutation loginItem($email:String!, $password:String!){
            login(email: $email, password: $password) {
                token
                user {
                    email,
                    request_status,
                    name,
                    id
                }
            }
        }
    `;

    const response = yield client
      .mutate({
        mutation: loginQuery,
        variables:{
          email:loginData.email,
          password:loginData.password
        }
      })
      .then(result => {
        return {
          success:true,
          payload:result
        };
      }
    ).
    catch(e => {
        return {
            success:false,
            payload:e
        };
    })
    
    return response;
      
}
