import {
    gql
} from "@apollo/client";
import { privateClient as client } from '..';
import { commonErrorHandling } from '../Utils/index';

export default function* productImagesSaga(data) {
    const query = gql`  
    query get_product_image(
        $product_id:Int,
      ){
        getProductGalleries( product_id: $product_id,){
            galleries{
              id,
              source,
              source_list,
              title
            }
          }     
   }`;

    return yield client
        .query({
            query: query,
            variables: {
                product_id: data.product_id,
            },
            fetchPolicy: 'no-cache'
        })
        .then(result => {
            return {
                success: true,
                payload: result
            };
        }
        ).
        catch(e => {
            commonErrorHandling(e)
            return {
                success: false,
                payload: e
            };
        });
}