import API from '../constant'
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  gql,
  createHttpLink
} from "@apollo/client";
import { setContext } from '@apollo/client/link/context';


const httpLink = createHttpLink({
    uri: API.url,
});

/* Private api handler */
const privateAuthLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem('AccessToken');
    const xAuthApi = API.xAuthToken;
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token,
        "x-auth-token":xAuthApi
      }
    }
});

/* Public api handler */
const publicAuthLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
 
  const xAuthApi = API.xAuthToken;
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      "x-auth-token":xAuthApi
    }
  }
});

export const privateClient = new ApolloClient({
  link: privateAuthLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export const publicClient = new ApolloClient({
  link: publicAuthLink.concat(httpLink),
  cache: new InMemoryCache()
});

