// login
export const LOGIN_REQUESTED = 'LOGIN_REQUESTED';
export const LOGIN_PROCESSING = 'LOGIN_PROCESSING';
export const LOGIN_SUCCESSED = 'LOGIN_SUCCESSED';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGIN_RESET_REQUESTED = 'LOGIN_RESET_REQUESTED';




export const LOGOUT_REQUESTED = 'LOGOUT_REQUESTED';
export const LOGOUT_PROCESSING = 'LOGOUT_PROCESSING';
export const LOGOUT_SUCCESSED = 'LOGOUT_SUCCESSED';
export const LOGOUT_FAILED = 'LOGOUT_FAILED';
export const RESETSTATE_REQUESTED = 'RESETSTATE_REQUESTED';

export const FORGOT_PASSWORD_REQUESTED = 'FORGOT_PASSWORD_REQUESTED';
export const FORGOT_PASSWORD_PROCESSING = 'FORGOT_PASSWORD_PROCESSING';
export const FORGOT_PASSWORD_SUCCESSED = 'FORGOT_PASSWORD_SUCCESSED';
export const FORGOT_PASSWORD_FAILED = 'FORGOT_PASSWORD_FAILED';

export const RESET_PASSWORD_REQUESTED = 'RESET_PASSWORD_REQUESTED';
export const RESET_PASSWORD_PROCESSING = 'RESET_PASSWORD_PROCESSING';
export const RESET_PASSWORD_SUCCESSED = 'RESET_PASSWORD_SUCCESSED';
export const RESET_PASSWORD_FAILED = 'RESET_PASSWORD_FAILED';

//Registration
export const REGISTRATION_REQUESTED = 'REGISTRATION_REQUESTED';
export const REGISTRATION_PROCESSING = 'REGISTRATION_PROCESSING';
export const REGISTRATION_SUCCESSED = 'REGISTRATION_SUCCESSED';
export const REGISTRATION_FAILED = 'REGISTRATION_FAILED';
//service

export const SERVICE_LIST_REQUESTED = 'SERVICE_LIST_REQUESTED';
export const SERVICE_LIST_PROCESSING = 'SERVICE_LIST_PROCESSING';
export const SERVICE_LIST_SUCCESSED = 'SERVICE_LIST_SUCCESSED';
export const SERVICE_LIST_FAILED = 'SERVICE_LIST_FAILED';

export const ISSERVICE_ACTIVE_REQUESTED = 'ISSERVICE_ACTIVE_REQUESTED';
export const ISSERVICE_ACTIVE_PROCESSING = 'ISSERVICE_ACTIVE_PROCESSING';
export const ISSERVICE_ACTIVE_SUCCESSED = 'ISSERVICE_ACTIVE_SUCCESSED';
export const ISSERVICE_ACTIVE_FAILED = 'ISSERVICE_ACTIVE_FAILED';

export const UPDATESERVICE_STATUS_REQUESTED = 'UPDATESERVICE_STATUS_REQUESTED';
export const UPDATESERVICE_STATUS_PROCESSING = 'UPDATESERVICE_STATUS_PROCESSING';
export const UPDATESERVICE_STATUS_SUCCESSED = 'UPDATESERVICE_STATUS_SUCCESSED';
export const UPDATESERVICE_STATUS_FAILED = 'UPDATESERVICE_STATUS_FAILED';

export const SERVICE_DETAILS_REQUESTED = 'SERVICE_DETAILS_REQUESTED';
export const SERVICE_DETAILS_PROCESSING = 'SERVICE_DETAILS_PROCESSING';
export const SERVICE_DETAILS_SUCCESSED = 'SERVICE_DETAILS_SUCCESSED';
export const SERVICE_DETAILS_FAILED = 'SERVICE_DETAILS_FAILED';

export const SERVICE_AUTH_FIELDS_REQUESTED = 'SERVICE_AUTH_FIELDS_REQUESTED';
export const SERVICE_AUTH_FIELDS_PROCESSING = 'SERVICE_AUTH_FIELDS_PROCESSING';
export const SERVICE_AUTH_FIELDS_SUCCESSED = 'SERVICE_AUTH_FIELDS_SUCCESSED';
export const SERVICE_AUTH_FIELDS_FAILED = 'SERVICE_AUTH_FIELDS_FAILED';

export const SERVICE_APPROVED_LIST_REQUESTED = 'SERVICE_APPROVED_LIST_REQUESTED';
export const SERVICE_APPROVED_LIST_PROCESSING = 'SERVICE_APPROVED_LIST_PROCESSING';
export const SERVICE_APPROVED_LIST_SUCCESSED = 'SERVICE_APPROVED_LIST_SUCCESSED';
export const SERVICE_APPROVED_LIST_FAILED = 'SERVICE_APPROVED_LIST_FAILED';

export const SERVICE_REQUEST_SAVE_REQUESTED = 'SERVICE_REQUEST_SAVE_REQUESTED';
export const SERVICE_REQUEST_SAVE_PROCESSING = 'SERVICE_REQUEST_SAVE_PROCESSING';
export const SERVICE_REQUEST_SAVE_SUCCESSED = 'SERVICE_REQUEST_SAVE_SUCCESSED';
export const SERVICE_REQUEST_SAVE_FAILED = 'SERVICE_REQUEST_SAVE_FAILED';

export const SERVICE_VENDOR_INTEGRATION_REQUESTED = 'SERVICE_VENDOR_INTEGRATION_REQUESTED';
export const SERVICE_VENDOR_INTEGRATION_PROCESSING = 'SERVICE_VENDOR_INTEGRATION_PROCESSING';
export const SERVICE_VENDOR_INTEGRATION_SUCCESSED = 'SERVICE_VENDOR_INTEGRATION_SUCCESSED';
export const SERVICE_VENDOR_INTEGRATION_FAILED = 'SERVICE_VENDOR_INTEGRATION_FAILED';

export const REQUEST_SERVICE_REQUESTED = 'REQUEST_SERVICE_REQUESTED';
export const REQUEST_SERVICE_PROCESSING = 'REQUEST_SERVICE_PROCESSING';
export const REQUEST_SERVICE_SUCCESSED = 'REQUEST_SERVICE_SUCCESSED';
export const REQUEST_SERVICE_FAILED = 'REQUEST_SERVICE_FAILED';

export const CROW_SERVICE_REQUESTED = 'CROW_SERVICE_REQUESTED';
export const CROW_SERVICE_PROCESSING = 'CROW_SERVICE_PROCESSING';
export const CROW_SERVICE_SUCCESSED = 'CROW_SERVICE_SUCCESSED';
export const CROW_SERVICE_FAILED = 'CROW_SERVICE_FAILED';

//products

export const PRODUCT_LIST_REQUESTED = 'PRODUCT_LIST_REQUESTED';
export const PRODUCT_LIST_PROCESSING = 'PRODUCT_LIST_PROCESSING';
export const PRODUCT_LIST_SUCCESSED = 'PRODUCT_LIST_SUCCESSED';
export const PRODUCT_LIST_FAILED = 'PRODUCT_LIST_FAILED';

export const ATTRIBUTES_ADVANCE_SEARCH_REQUESTED = 'ATTRIBUTES_ADVANCE_SEARCH_REQUESTED';
export const ATTRIBUTES_ADVANCE_SEARCH_PROCESSING = 'ATTRIBUTES_ADVANCE_SEARCH_PROCESSING';
export const ATTRIBUTES_ADVANCE_SEARCH_SUCCESSED = 'ATTRIBUTES_ADVANCE_SEARCH_SUCCESSED';
export const ATTRIBUTES_ADVANCE_SEARCH_FAILED = 'ATTRIBUTES_ADVANCE_SEARCH_FAILED';

export const ATTRIBUTES_FILTER_LIST_REQUESTED = 'ATTRIBUTES_FILTER_LIST_REQUESTED';
export const ATTRIBUTES_FILTER_LIST_PROCESSING = 'ATTRIBUTES_FILTER_LIST_PROCESSING';
export const ATTRIBUTES_FILTER_LIST_SUCCESSED = 'ATTRIBUTES_FILTER_LIST_SUCCESSED';
export const ATTRIBUTES_FILTER_LIST_FAILED = 'ATTRIBUTES_FILTER_LIST_FAILED';

export const ATTRIBUTES_LIST_REQUESTED = 'ATTRIBUTES_LIST_REQUESTED';
export const ATTRIBUTES_LIST_PROCESSING = 'ATTRIBUTES_LIST_PROCESSING';
export const ATTRIBUTES_LIST_SUCCESSED = 'ATTRIBUTES_LIST_SUCCESSED';
export const ATTRIBUTES_LIST_FAILED = 'ATTRIBUTES_LIST_FAILED';

export const CATEGORY_FILTER_LIST_REQUESTED = 'CATEGORY_FILTER_LIST_REQUESTED';
export const CATEGORY_FILTER_LIST_PROCESSING = 'CATEGORY_FILTER_LIST_PROCESSING';
export const CATEGORY_FILTER_LIST_SUCCESSED = 'CATEGORY_FILTER_LIST_SUCCESSED';
export const CATEGORY_FILTER_LIST_FAILED = 'CATEGORY_FILTER_LIST_FAILED';

export const FTP_CONNECTION_REQUESTED = 'FTP_CONNECTION_REQUESTED';
export const FTP_CONNECTION_PROCESSING = 'FTP_CONNECTION_PROCESSING';
export const FTP_CONNECTION_SUCCESSED = 'FTP_CONNECTION_SUCCESSED';
export const FTP_CONNECTION_FAILED = 'FTP_CONNECTION_FAILED';


export const USER_META_SUBMIT_REQUESTED = 'USER_META_SUBMIT_REQUESTED';
export const USER_META_SUBMIT_PROCESSING = 'USER_META_SUBMIT_PROCESSING';
export const USER_META_SUBMIT_SUCCESSED = 'USER_META_SUBMIT_SUCCESSED';
export const USER_META_SUBMIT_FAILED = 'USER_META_SUBMIT_FAILED';


export const IS_USER_WD_INTEGRATED_REQUESTED = 'IS_USER_WD_INTEGRATED_REQUESTED';
export const IS_USER_WD_INTEGRATED_PROCESSING = 'IS_USER_WD_INTEGRATED_PROCESSING';
export const IS_USER_WD_INTEGRATED_SUCCESSED = 'IS_USER_WD_INTEGRATED_SUCCESSED';
export const IS_USER_WD_INTEGRATED_FAILED = 'IS_USER_WD_INTEGRATED_FAILED';



export const CATALOG_RULE_SUBMIT_REQUESTED = 'CATALOG_RULE_SUBMIT_REQUESTED';
export const CATALOG_RULE_SUBMIT_PROCESSING = 'CATALOG_RULE_SUBMIT_PROCESSING';
export const CATALOG_RULE_SUBMIT_SUCCESSED = 'CATALOG_RULE_SUBMIT_SUCCESSED';
export const CATALOG_RULE_SUBMIT_FAILED = 'CATALOG_RULE_SUBMIT_FAILED';

export const GET_CATALOG_RULE_REQUESTED = 'GET_CATALOG_RULE_REQUESTED';
export const GET_CATALOG_RULE_PROCESSING = 'GET_CATALOG_RULE_PROCESSING';
export const GET_CATALOG_RULE_SUCCESSED = 'GET_CATALOG_RULE_SUCCESSED';
export const GET_CATALOG_RULE_FAILED = 'GET_CATALOG_RULE_FAILED';

export const DASHBOARD_REQUESTED = 'DASHBOARD_REQUESTED';
export const DASHBOARD_PROCESSING = 'DASHBOARD_PROCESSING';
export const DASHBOARD_SUCCESSED = 'DASHBOARD_SUCCESSED';
export const DASHBOARD_FAILED = 'DASHBOARD_FAILED';

export const PROFILE_UPLOAD_REQUESTED = 'PROFILE_UPLOAD_REQUESTED';
export const PROFILE_UPLOAD_PROCESSING = 'PROFILE_UPLOAD_PROCESSING';
export const PROFILE_UPLOAD_SUCCESSED = 'PROFILE_UPLOAD_SUCCESSED';
export const PROFILE_UPLOAD_FAILED = 'PROFILE_UPLOAD_FAILED';


export const GETUSER_DATA_REQUESTED = 'GETUSER_DATA_REQUESTED';
export const GETUSER_DATA_PROCESSING = 'GETUSER_DATA_PROCESSING';
export const GETUSER_DATA_SUCCESSED = 'GETUSER_DATA_SUCCESSED';
export const GETUSER_DATA_FAILED = 'GETUSER_DATA_FAILED';

export const CHANGE_PRODUCT_STATUS_REQUESTED = 'CHANGE_PRODUCT_STATUS_REQUESTED';
export const CHANGE_PRODUCT_STATUS_PROCESSING = 'CHANGE_PRODUCT_STATUS_PROCESSING';
export const CHANGE_PRODUCT_STATUS_SUCCESSED = 'CHANGE_PRODUCT_STATUS_SUCCESSED';
export const CHANGE_PRODUCT_STATUS_FAILED = 'CHANGE_PRODUCT_STATUS_FAILED';

export const CHANGE_PRICE_REQUESTED = 'CHANGE_PRICE_REQUESTED';
export const CHANGE_PRICE_PROCESSING = 'CHANGE_PRICE_PROCESSING';
export const CHANGE_PRICE_SUCCESSED = 'CHANGE_PRICE_SUCCESSED';
export const CHANGE_PRICE_FAILED = 'CHANGE_PRICE_FAILED';

export const REMOVE_CHANGE_PRICE_REQUESTED = 'REMOVE_CHANGE_PRICE_REQUESTED';
export const REMOVE_CHANGE_PRICE_PROCESSING = 'REMOVE_CHANGE_PRICE_PROCESSING';
export const REMOVE_CHANGE_PRICE_SUCCESSED = 'REMOVE_CHANGE_PRICE_SUCCESSED';
export const REMOVE_CHANGE_PRICE_FAILED = 'REMOVE_CHANGE_PRICE_FAILED';

export const VIEW_APPLIED_CATALOG_RULE_REQUESTED = 'VIEW_APPLIED_CATALOG_RULE_REQUESTED';
export const VIEW_APPLIED_CATALOG_RULE_PROCESSING = 'VIEW_APPLIED_CATALOG_RULE_PROCESSING';
export const VIEW_APPLIED_CATALOG_RULE_SUCCESSED = 'VIEW_APPLIED_CATALOG_RULE_SUCCESSED';
export const VIEW_APPLIED_CATALOG_RULE_FAILED = 'VIEW_APPLIED_CATALOG_RULE_FAILED';

export const PRODUCT_CUSTOMIZE_REQUESTED = 'PRODUCT_CUSTOMIZE_REQUESTED'
export const PRODUCT_CUSTOMIZE_PROCESSING = 'PRODUCT_CUSTOMIZE_PROCESSING'
export const PRODUCT_CUSTOMIZE_SUCCESSED = 'PRODUCT_CUSTOMIZE_SUCCESSED'
export const PRODUCT_CUSTOMIZE_FAILED = 'PRODUCT_CUSTOMIZE_FAILED'

export const PRODUCT_IMAGES_REQUESTED = 'PRODUCT_IMAGES_REQUESTED'
export const PRODUCT_IMAGES_PROCESSING = 'PRODUCT_IMAGES_PROCESSING'
export const PRODUCT_IMAGES_SUCCESSED = 'PRODUCT_IMAGES_SUCCESSED'
export const PRODUCT_IMAGES_FAILED = 'PRODUCT_IMAGES_FAILED'

export const REMOVE_PRODUCT_IMAGES_REQUESTED = 'REMOVE_PRODUCT_IMAGES_REQUESTED'
export const REMOVE_PRODUCT_IMAGES_PROCESSING = 'REMOVE_PRODUCT_IMAGES_PROCESSING'
export const REMOVE_PRODUCT_IMAGES_SUCCESSED = 'REMOVE_PRODUCT_IMAGES_SUCCESSED'
export const REMOVE_PRODUCT_IMAGES_FAILED = 'REMOVE_PRODUCT_IMAGES_FAILED'

export const UPLOAD_PRODUCT_IMAGES_REQUESTED = 'UPLOAD_PRODUCT_IMAGES_REQUESTED'
export const UPLOAD_PRODUCT_IMAGES_PROCESSING = 'UPLOAD_PRODUCT_IMAGES_PROCESSING'
export const UPLOAD_PRODUCT_IMAGES_SUCCESSED = 'UPLOAD_PRODUCT_IMAGES_SUCCESSED'
export const UPLOAD_PRODUCT_IMAGES_FAILED = 'UPLOAD_PRODUCT_IMAGES_FAILED'

export const GET_PRODUCT_CUSTOMIZE_REQUESTED = 'GET_PRODUCT_CUSTOMIZE_REQUESTED'
export const GET_PRODUCT_CUSTOMIZE_PROCESSING = 'GET_PRODUCT_CUSTOMIZE_PROCESSING'
export const GET_PRODUCT_CUSTOMIZE_SUCCESSED = 'GET_PRODUCT_CUSTOMIZE_SUCCESSED'
export const GET_PRODUCT_CUSTOMIZE_FAILED = 'GET_PRODUCT_CUSTOMIZE_FAILED'

export const SETTING_REQUESTED = 'SETTING_REQUESTED'
export const SETTING_PROCESSING = 'SETTING_PROCESSING'
export const SETTING_SUCCESSED = 'SETTING_SUCCESSED'
export const SETTING_FAILED = 'SETTING_FAILED'

