
import API from '../../constant'
import {
    ApolloClient,
    InMemoryCache,
    ApolloProvider,
    useQuery,
    gql,
    createHttpLink
  } from "@apollo/client";
import { privateClient as client } from '..';
import { commonErrorHandling } from '../Utils/index';

/* Product List Api */
export default function* productListSaga(data) {
   
  const query = gql`
  query productListItem($service_id:Int,$term:String,$pageNo:Int,$sort:Boolean!,$advanceFilter:[AdvanceFilter],$attributeFilter:[AdvanceFilter],$categories:[Int],$pageSize:Int,$isWarning:Boolean!,$isStock:Int,$stock:Int,$typeStock:String,$product_status:Int, $sub_product_status:Int){
    productList(service_id:$service_id,term:$term,pageNo:$pageNo,sort:$sort,advanceFilter:$advanceFilter,attributeFilter:$attributeFilter,categories:$categories,pageSize:$pageSize,isWarning:$isWarning,isStock:$isStock,stock:$stock,typeStock:$typeStock,product_status:$product_status, sub_product_status:$sub_product_status){
      totalProduct,
      totalWarningProduct,
      data{
          id,
          upc,
          sku,
          market_product_id,
          product_status,
          is_customized,
          price,
          final_price,
          map,
          msrp,
          listPrice,
          quantity,
          margin,
          attributes{
            id,
            name,
            attribute_code,
            value
          },
          categories{
            id,
            name
          },
          galleries{
            source,
            source_list,
            title
        }
      }
    }
    }
  `;
    return yield client
      .query({
        query: query,
        variables:{
          service_id:data.service_id,
          term:data.term,
          pageNo:data.pageNo,
          sort:data.sort,
          advanceFilter:data.advanceFilter,
          attributeFilter:data.attributeFilter,
          categories:data.categories,
          pageSize: data.pageSize?data.pageSize:10,
          isWarning:data.isWarning?data.isWarning:false,
          product_status: data.tabStatus,
          sub_product_status: data.subtabStatus,
          isStock:data.isStock,
          stock:parseInt(data.stock),
          typeStock:data.typeStock?data.typeStock:'='
          
        },
        fetchPolicy: 'no-cache',

      })
      .then(result => {
        return {
            success:true,
            payload:result
        };
      }
    ).
    catch(e => {
        console.log(e);
        commonErrorHandling( e)
        return {
            success:false,
            payload:e
        };
    });
      
}
