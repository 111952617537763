import { put, call, takeLatest } from 'redux-saga/effects';
import {


  SETTING_SUCCESSED,
  SETTING_FAILED,
  SETTING_REQUESTED,

} from '../../constant/actionTypes';


import  settingItemSaga  from '../../apis/Setting/setting';




export  function*  watchSettingList(action) {
  try {
    
    const data = yield  call( settingItemSaga, action.payload);
    
    if (data?.success && data?.success == true) {
      yield put({ type: SETTING_SUCCESSED, data: data?.payload?.data?.settings });
    } else {
      console.log( data.payload);
      yield put({ type: SETTING_FAILED, data: data.payload.message });
    }
  } catch (err) {
      console.log(err);
    yield put({ type: SETTING_FAILED, data: err?.response?.data?.data });
  }
}


export default function* watcher() {

  yield takeLatest(SETTING_REQUESTED, watchSettingList);

}