import {
    gql
} from "@apollo/client";
import { privateClient as client } from '..';
import { commonErrorHandling } from '../Utils/index';

export default function* isServiceActiveSaga(data) {
    const query = gql`  
    query is_service_active(
        $id:String,
      ){
        isServiceActive(id: $id){
            status
          }   
        }`;

    return yield client
        .query({
            query: query,
            variables: {
                id: data.id,
            },
            fetchPolicy: 'no-cache'
        })
        .then(result => {
            return {
                success: true,
                payload: result
            };
        }
        ).
        catch(e => {
            commonErrorHandling(e)
            return {
                success: false,
                payload: e
            };
        });
}