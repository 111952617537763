
import {
  gql
} from "@apollo/client";
import { publicClient as client } from '..';



export default function* registerSaga(registerData) {

  const registerQuery = gql`
    mutation registerItem($email:String!, $password:String!,$username:String!, $firstName:String!, $lastName:String!,$phone:String!,$country:String!,$street_address:String!,$address_line_2:String!,$city:String!,$state:String!,$store_name:String!,$zip_code:String!){
      registerUser(username: $username, password: $password, email: $email, firstName:$firstName,  lastName:$lastName,phone: $phone,country: $country,street_address: $street_address,address_line_2: $address_line_2,city: $city,state: $state,store_name: $store_name,zip_code: $zip_code ) {
          token
          user {
              email,
              request_status,
              name,
              id
          }
      }
  }
    `;

  return yield client
    .mutate({
      mutation: registerQuery,
      variables: {
        email: registerData.email,
        password: registerData.password,
        username: registerData.username,
        firstName: registerData.firstName,
        lastName: registerData.lastName,
        store_name: registerData.storename,
        phone: registerData.phone,
        country: registerData.selectcountry,
        state: registerData.selectstate,
        street_address: registerData.streetaddress,
        address_line_2: registerData.addressline2,
        city: registerData.city,
        zip_code: registerData.zipcode
      }
    })
    .then(result => {
      return {
        success: true,
        payload: result
      };
    }
    ).
    catch(e => {
      return {
        success: false,
        payload: e
      };
    });

}
