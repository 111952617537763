import {
    gql
} from "@apollo/client";
import { privateClient as client } from '..';
import { commonErrorHandling } from '../Utils/index';

export default function* changePriceSaga(data) {
    const query = gql`  
     query catalog_product_rule_submitItem(
         $service_id:Int,
         $product_id:Int,
         $markup_type:String,
         $markup_amount:Float){
        catalog_product_rule_submit(
              service_id: $service_id,
              product_id: $product_id,
              markup_type: $markup_type,
              markup_amount: $markup_amount,
      ){
          success,
          message          
        }      
    }`;

    return yield client
        .query({
            query: query,
            variables: {
                service_id: data.service_id,
                product_id: data.product_id,
                markup_type: data.markup_type,
                markup_amount: parseFloat(data.amount),
            },
            fetchPolicy: 'no-cache'
        })
        .then(result => {
            return {
                success: true,
                payload: result
            };
        }
        ).
        catch(e => {
            commonErrorHandling(e)
            return {
                success: false,
                payload: e
            };
        });
}