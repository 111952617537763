import React, { useEffect, useState } from 'react';
import cloudImg from '../../../assets/images/cloud.png';
import FullLayout from '../../Layouts/FullLayout';
import LoginWrap from '../../../components/LoginWrap';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import InputBox from '../../../components/Form/InputBox';
import PasswordInputBox from '../../../components/Form/PasswordInputBox';
import Checkbox from '../../../components/Form/Checkbox';
import {loginDetails} from '../../../action/Auth/login';
import { compose } from 'redux';
import { connect } from 'react-redux';
import ErrorMessage from '../../../components/Form/ErrorMessage';
import { Alert } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import { useParallax , Parallax } from 'react-scroll-parallax';

import '../../../assets/homePage/c584631aac3102923a80b2ea67c879c5.css';
import '../../../assets/homePage/febd65cce2e0af2e9000e8d9cfaf32c5.css'
import '../../../assets/homePage/homepage.css';
import '../../../assets/homePage/font.css';
import { Link } from 'react-router-dom';

const Aboutus = ({loginDetails,loginStore}) => {

  const history = useHistory();

  useEffect(() => {
    document.title = 'Cloud Connect - Distributor Feeds by Weapon Depot';
  }, []);

  function redirectTOLogin(){
    history.push("/login");
  }

  function goToAboutUs(){
    history.push("/about-us");
  }
  function goToFaqs(){
    history.push("/faqs");
  }

  

  function goToprivcyPolicy(){
    window.location.href = "https://www.weapondepot.com/weapon-depot-privacy-policy/";
  }

  return (
  
    <body className="brz">
        <div className='brz-root__container brz-reset-all'>
            <section data-uid="ueivhixnnkamwytthspolftzlgtszczymodv" id="ueivhixnnkamwytthspolftzlgtszczymodv" className="brz-section brz-css-vugti brz-css-fzjkd">
                <div className="brz-section__content brz-section--boxed brz-css-mwhob brz-css-sqsrr" data-custom-id="zdnfsxgiwpnitrsqukosgponckzvudqzbpuc">
                    <div className="brz-bg">
                        <div className="brz-bg-color"></div>
                    </div>
                    <div className="brz-container brz-css-pbhem">
                        <div className="brz-row__container brz-css-fgwvm" data-custom-id="blsklzmdiizssknodddbbmyyoinpofjtqqgf">
                            <div className="brz-row brz-css-vxblm brz-css-twejo">
                                <div className="brz-columns brz-css-dynog brz-css-xqjbh" data-custom-id="hvdormvyyzhmkrcqxgcgitgqvpmcbqnrnoyp">
                                    <div className="brz-bg">
                                        <div className="brz-bg-color"></div>
                                    </div>
                                    <div className="brz-column__items brz-css-ecksy brz-css-jugzp">
                                        <div className="brz-css-ouobs brz-css-xzspy brz-wrapper">
                                            <div className="brz-image brz-css-kfgvz">
                                                <picture className="brz-picture brz-d-block brz-p-relative brz-css-bwlsg" data-custom-id="xoebioevncwdstaufovdbeyqqkhslyrvgdte">
                                                    <source srcset="assets/img/b4c610c8d9007deda4747fa38c5d0124.png 1x, assets/img/7eed853163742f6ff009af52ff26e8ba.png 2x" media="(min-width: 992px)" />
                                                    <source srcset="assets/img/e5d9ba31fdb7e4837c261062b97ff236.png 1x, assets/img/161ea182b5a3faabac4456de26e3105d.png 2x" media="(min-width: 768px)" />
                                                    <img className="brz-img" srcset="assets/img/753f8b29c69201c3320abcb3f26c2921.png 1x, assets/img/171293ae692a038cff0b0b2fe45f949c.png 2x" src="assets/img/b4c610c8d9007deda4747fa38c5d0124.png"  draggable="false" loading="lazy" />
                                                </picture>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="brz-columns brz-css-dynog brz-css-qdygj main-text" data-custom-id="oncawotjufeuqiofsqfmyqemzosqrfgddurc">
                                    <div className="brz-column__items brz-css-ecksy brz-css-ryvlv">
                                        <div className="brz-css-ouobs brz-wrapper">
                                            <div className="brz-rich-text brz-rich-text__custom brz-css-rmcic" data-custom-id="esxmgmdlctipqkfchbvmgnbdywhetdmqfhor">
                                                <div className="white-space-normal">
                                                    <p className="brz-css-xbqfe" data-uniq-id="jdzbw" data-generated-css="brz-css-lxjkp"><strong style={{color: "rgb(249, 157, 0)"}}>Cloud Connect</strong></p>
                                                    <p className="brz-css-vlrgl" data-uniq-id="eceeu" data-generated-css="brz-css-xcjoc"><em className="brz-cp-color7">Powered by </em><a href="https://www.weapondepot.com" target="_blank"  className="brz-cp-color7 link--external" data-brz-link-type="external"><em>WeaponDepot.com</em></a><em className="brz-cp-color7"> </em></p>
                                                </div>
                                            </div>
                                          
                                        </div>
                                       
                                    </div>
                                </div>
                               
                            </div>
                            <div className="brz-columns brz-css-dynog brz-css-qdygj lohin-htn" >
                                    <button onClick={() => { redirectTOLogin()}} class="brz-btn brz-btn-submit brz-css-wmnit brz-css-rhvdx" data-custom-id="eykylnpiqtmecbwaxhypadsiwvlxrvrwwcyc"><span class="brz-span brz-text__editor">Login</span></button>
                            </div>
                           
                        </div>
                        
                    </div>
                </div>
            </section>
            <section data-uid="coivtdumixpjviidlnadxdzyiccjgmmsvkte" id="coivtdumixpjviidlnadxdzyiccjgmmsvkte" className="brz-section brz-css-vugti new-secontion-mb">
                <div className="brz-section__content brz-section--boxed brz-css-mwhob brz-css-jakdx" data-custom-id="snqwmrictscnwkevvcgydzzyyqfanlmfoqew">
                    <div className="brz-container brz-css-pbhem">
                        <div>
                            <h1 className='bold mb-5'>Streaming Data Solutions on Weapon Depot with Cloud</h1>
                        </div>
                        <div>
                            <p className='mb-3 f-18'>Today, there are multiple connected devices that retailers use to connect inventory with their product suppliers and distributors.  Having the ability to stream this data, and live feed products and product data in real-time is critical to selling on Weapon Depot and leveraging Weapon Depot applications. Customers are using Weapon Depot Cloud to quickly and reliably stream data from distributors and POS  into Weapon Depot for analytics, sales, and to trigger more sales. </p>
                            <p className='mb-3 f-18'>While listing products on Weapon Depot is a great way to sell your products and expose them to millions of customers, adding multiple items to your Weapon Depot Vendor account and Storefront can be a time-consuming process. Instead of adding products one-by-one, sellers using a Weapon Depot Cloud accounts can use Weapon Depot tools to upload product details in bulk, and live feed data from distributors such as SportSouth and RSR. </p>
                            <p className='mb-3 f-18'>Running a business as a Weapon Depot seller and vendor means that you must keep your inventory up-to-date. If you don't have a software service that does it for you, you'll need to manually download inventory reports, make necessary changes, and then upload them to Weapon Depot. After you've uploaded your edited inventory reports, the changes will be reflected on Weapon Depot’s website and your online inventory will match your physical inventory offline.  Cloud streamlines this process and makes uploading inventory as easy as the click of a button. </p>
                        </div>
                    </div>
                </div>
            </section>
            <section data-uid="coivtdumixpjviidlnadxdzyiccjgmmsvkte" id="coivtdumixpjviidlnadxdzyiccjgmmsvkte" className="brz-section brz-css-vugti">
                <div className="brz-section__content brz-section--boxed brz-css-mwhob brz-css-jakdx" data-custom-id="snqwmrictscnwkevvcgydzzyyqfanlmfoqew">
                    <div className="brz-bg">
                        <div className="brz-bg-color"></div>
                    </div>
                    <div className="brz-container brz-css-pbhem">
                        <div className="brz-row__container brz-css-fgwvm brz-css-biqqb" data-custom-id="dgwbyfldkhaanifsnwjsfzuqmjzjysydcsxj">
                            <div className="brz-row brz-css-vxblm brz-css-twejo">
                                <div className="brz-columns brz-css-dynog brz-css-knzat" data-custom-id="ydoaucpslmclcoekmnxfaphrqpogftxhrarc">
                                    <div className="brz-column__items brz-css-ecksy brz-css-sojug">
                                        <div className="brz-css-ouobs brz-css-njfvk brz-wrapper">
                                            <div className="brz-rich-text brz-rich-text__custom brz-css-rmcic" data-custom-id="urwgnegvebbnahwzgkejonxkkveofybefddi">
                                                <div>
                                                    <p className="brz-css-ajpoj" data-generated-css="brz-css-uamqg" data-uniq-id="lkzuw"><span className="brz-cp-color4">Copyright </span><span style={{color: "rgb(253, 253, 253)"}}>&#xA9; {(new Date().getFullYear())} Weapon Depot</span></p>
                                                </div>
                                                <div>
                                                    <p className="brz-css-ajpoj url-view cursor-pointer" onClick={()=>goToAboutUs()} data-generated-css="brz-css-uamqg" data-uniq-id="lkzuw">
                                                    <Link to='/about-us'><span className="brz-cp-color4">About us </span></Link> 
                                                        </p>
                                                </div>
                                                <div>
                                                    <p className="brz-css-ajpoj url-view cursor-pointer" onClick={()=>goToFaqs()} data-generated-css="brz-css-uamqg" data-uniq-id="lkzuw">
                                                    <span className="brz-cp-color4">FAQs </span>
                                                        </p>
                                                </div>
                                                <div>
                                                    <p className="brz-css-ajpoj url-view cursor-pointer"  data-generated-css="brz-css-uamqg" data-uniq-id="lkzuw">
                                                      
                                                        <a href='https://www.weapondepot.com/weapon-depot-privacy-policy/'><span className="brz-cp-color4">Privacy policy </span></a> 
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="brz-columns brz-css-dynog brz-css-mwvns" data-custom-id="julbyhtxpnvltvrlrwentibemdticctprovv">
                                    <div className="brz-column__items brz-css-ecksy">
                                        <div className="brz-css-ouobs brz-wrapper">
                                            <div className="brz-image brz-css-sleox">
                                                <picture className="brz-picture brz-d-block brz-p-relative brz-css-ecexl" data-custom-id="bqlvsolbgmtuuoswjejckjifqpmrtdmbhcjx">
                                                    <source srcset="assets/img/54ce783012ec7d0a1489f7f648c993a9.png 1x, assets/img/9f6ba64d532e12b26882810ae053fb22.png 2x" media="(min-width: 992px)" />
                                                    <source srcset="assets/img/510611ef3d045170a216eaf59a460666.png 1x, assets/img/2cf27d114e395d44f038164d4fbef438.png 2x" media="(min-width: 768px)" /> 
                                                    <img className="brz-img" srcset="assets/img/b748850b5438cfced939e16e90ccb72f.png 1x, assets/img/9dbe86270799300d3e07088e0ea3b6f2.png 2x" src="assets/img/54ce783012ec7d0a1489f7f648c993a9.png" alt draggable="false" loading="lazy" />
                                                </picture>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </div>
    </body>
  );
};


const mapStateToProps = state => {
    return {
        loginStore: state.loginStore,
    };
  };
  
  const mapDispatchToProps = {
    loginDetails
 
  };
  
  export default compose(
    connect(mapStateToProps, mapDispatchToProps),
  )(Aboutus);