import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';


function FullLayout({ children, className }) {
 

  return (
    <div className={className?'login-page '+className:'login-page'}>
        <div className="admin-wrap">
            <div className="container">
                {children}
            </div>
        </div>
    </div>
  );
}

const mapStateToProps = state => {
  return {

  };
};

const mapDispatchToProps = dispatch => {
  return {
   
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FullLayout);
