import { put, call, takeLatest } from 'redux-saga/effects';
import {


  USER_META_SUBMIT_REQUESTED,
  USER_META_SUBMIT_FAILED,
  USER_META_SUBMIT_SUCCESSED,

  IS_USER_WD_INTEGRATED_REQUESTED,
  IS_USER_WD_INTEGRATED_FAILED,
  IS_USER_WD_INTEGRATED_SUCCESSED,
  GETUSER_DATA_REQUESTED,
  GETUSER_DATA_PROCESSING,
  GETUSER_DATA_SUCCESSED,
  GETUSER_DATA_FAILED,


} from '../../constant/actionTypes';

import submitUserMetaStore from '../../apis/User/userMeta';
import isWdAuthIntegrated from '../../apis/User/isWdAuthIntegrated';
import getUserData from '../../apis/User/getUserData';

export  function*  watchUserMetaIntegration(action) {
  try {
    const data = yield  call( submitUserMetaStore, action.payload);
    
    if (data?.success && data?.success == true) {
      yield put({ type: USER_META_SUBMIT_SUCCESSED, data: data?.payload?.data?.serviceVendorIntegration });
    } else {
  
      yield put({ type: USER_META_SUBMIT_FAILED, data: data.payload.message });
    }
  } catch (err) {
      console.log(err);
    yield put({ type: USER_META_SUBMIT_FAILED, data: err?.response?.data?.data });
  }
}

export  function*  watchIsWdIntegrated(action) {
    try {
      const data = yield  call( isWdAuthIntegrated, action.payload);
    
      if (data?.success && data?.success == true) {
       
        yield put({ type: IS_USER_WD_INTEGRATED_SUCCESSED, data: data?.payload?.data?.isWdAuthIntegrated });
      } else {
    
        yield put({ type: IS_USER_WD_INTEGRATED_FAILED, data: data.payload.message });
      }
    } catch (err) {
        console.log(err);
      yield put({ type: IS_USER_WD_INTEGRATED_FAILED, data: err?.response?.data?.data });
    }
  }

  export  function*  watchGetUserData(action) {
    try {
      const data = yield  call( getUserData, action.payload);
      
      if (data?.success && data?.success == true) {
       
        yield put({ type: GETUSER_DATA_SUCCESSED, data: data?.payload?.data?.getUserData });
      } else {
    
        yield put({ type: GETUSER_DATA_FAILED, data: data.payload.message });
      }
    } catch (err) {
        console.log(err);
      yield put({ type: GETUSER_DATA_FAILED, data: err?.response?.data?.data });
    }
  }

export default function* watcher() {

  yield takeLatest(USER_META_SUBMIT_REQUESTED, watchUserMetaIntegration);
  yield takeLatest(IS_USER_WD_INTEGRATED_REQUESTED, watchIsWdIntegrated);
  yield takeLatest(GETUSER_DATA_REQUESTED, watchGetUserData);
}