import { put, call, takeLatest } from 'redux-saga/effects';
import {
  LOGIN_SUCCESSED,
  LOGIN_FAILED,
  LOGIN_REQUESTED,
  LOGOUT_REQUESTED,
  LOGOUT_FAILED,
  LOGOUT_SUCCESSED,

  REGISTRATION_REQUESTED,
  REGISTRATION_SUCCESSED,
  REGISTRATION_FAILED,


  FORGOT_PASSWORD_SUCCESSED,
  FORGOT_PASSWORD_FAILED,
  FORGOT_PASSWORD_REQUESTED,

  RESET_PASSWORD_SUCCESSED,
  RESET_PASSWORD_FAILED,
  RESET_PASSWORD_REQUESTED,

  PROFILE_UPLOAD_REQUESTED,
  PROFILE_UPLOAD_SUCCESSED,
  PROFILE_UPLOAD_FAILED,
} from '../../constant/actionTypes';
import  loginSaga  from '../../apis/Auth/login';
import registerSaga from '../../apis/Auth/register';
import  resetPasswordSaga  from '../../apis/Auth/resetpassword';
import  forgotPasswordSaga  from '../../apis/Auth/forgotpassword';
import { resetAllState } from '../../action/Auth/logout';
import profileUpload from '../../apis/Auth/profileUpload';

import {store} from '../store';


export  function*  watchLoginDetails(action) {
  try {
    const loginData = yield  call( loginSaga, action.payload);
  
    if (loginData?.success && loginData?.success == true) {
      localStorage.setItem('AccessToken', loginData?.payload.data?.login?.token);
      localStorage.setItem('user', JSON.stringify(loginData?.payload.data?.login?.user));
     
      yield put({ type: LOGIN_SUCCESSED, data: loginData?.payload.data?.login });
    } else {
  
      yield put({ type: LOGIN_FAILED, data: loginData.payload.message });
    }
  } catch (err) {
      console.log(err);
    yield put({ type: LOGIN_FAILED, data: err?.response?.data?.data });
  }
}

export  function*  watchRegister(action) {
  try {
    const registerData = yield  call( registerSaga, action.payload);
  
    if (registerData?.success && registerData?.success == true) {
      localStorage.setItem('AccessToken', registerData?.payload.data?.registerUser?.token);
      localStorage.setItem('user', JSON.stringify(registerData?.payload.data?.registerUser?.user));
     
      yield put({ type: REGISTRATION_SUCCESSED, data: registerData?.payload.data?.registerUser });
    } else {
  
      yield put({ type: REGISTRATION_FAILED, data: registerData.payload.message });
    }
  } catch (err) {
      console.log(err);
    yield put({ type: REGISTRATION_FAILED, data: err?.response?.data?.data });
  }
}

export  function*  watchForgotPasswordDetails(action) {
  try {
    const data = yield  call( forgotPasswordSaga, action.payload);
  
    if (data?.success && data?.success == true) {     
      yield put({ type: FORGOT_PASSWORD_SUCCESSED, data: data?.payload.data });
    } else {
  
      yield put({ type: FORGOT_PASSWORD_FAILED, data: data.payload.message });
    }
  } catch (err) {
      console.log(err);
    yield put({ type: FORGOT_PASSWORD_FAILED, data: err?.response?.data?.data });
  }
}

export  function*  watchResetPasswordDetails(action) {
  try {
    const data = yield  call( resetPasswordSaga , action.payload);
  
    if (data?.success && data?.success == true) {     
      yield put({ type: RESET_PASSWORD_SUCCESSED, data: data?.payload.data });
    } else {
  
      yield put({ type: RESET_PASSWORD_FAILED, data: data.payload.message });
    }
  } catch (err) {
      console.log(err);
    yield put({ type: RESET_PASSWORD_FAILED, data: err?.response?.data?.data });
  }
}

export  function*  watchProfileUpload(action) {
  try {
    const data = yield  call( profileUpload , action.payload);
    
    if (data?.data?.profileUpload?.status) {     
      yield put({ type: PROFILE_UPLOAD_SUCCESSED, data: data?.data?.profileUpload });
      action.callback();
    } else {
  
      yield put({ type: PROFILE_UPLOAD_FAILED, data: 'Error' });
    }
  } catch (err) {
      console.log(err);
    yield put({ type: PROFILE_UPLOAD_FAILED, data: err?.response?.data?.data });
  }
}

export function* watchUserLogout(action) {
  try {
    localStorage.removeItem('AccessToken')
    localStorage.removeItem('user')
    //localStorage.clear();
    yield put({ type: LOGOUT_SUCCESSED, data: "Done" });
    //store.dispatch(resetAllState());
  } catch (err) {
      console.log(err);
    yield put({ type: LOGOUT_FAILED, data: err?.response?.data?.data });
  }
  
}

export default function* watcher() {
  yield takeLatest(LOGIN_REQUESTED, watchLoginDetails);
  yield takeLatest(REGISTRATION_REQUESTED, watchRegister);
  yield takeLatest(LOGOUT_REQUESTED, watchUserLogout);
  yield takeLatest(FORGOT_PASSWORD_REQUESTED, watchForgotPasswordDetails);
  yield takeLatest(RESET_PASSWORD_REQUESTED, watchResetPasswordDetails);
  yield takeLatest(PROFILE_UPLOAD_REQUESTED, watchProfileUpload);
  
 
}