
import {
    gql
  } from "@apollo/client";
import { privateClient as client } from '..';
import { commonErrorHandling } from '../Utils/index';

export default function* getUserData() {
   

    const servicesList = gql`
        {
          getUserData{
              id,
              email,
              name,
              username,
              profile_image
            }
        }
    `;
    const response = yield client
      .query({
        query: servicesList,
        fetchPolicy: 'no-cache',
      })
      .then(result => {

        return {
            success:true,
            payload:result
        };
      }
    ).
    catch(e => {
        commonErrorHandling( e)
        return {
            success:false,
            payload:e
        };
    })
    
    return response;
      
}
