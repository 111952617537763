
import {
  gql
} from "@apollo/client";
import { privateClient as client } from '..';
import { commonErrorHandling } from '../Utils/index';


export default function* serviceRequestSaga(data) {

  const query = gql`
      mutation serviceRequestAccessItem($services:[String!]){
        serviceRequestAccess(services:$services) {
              message
          }
      }
  `;

  const response = yield client
    .mutate({
      mutation: query,
      variables:{
        services:data.services,
      }
    })
    .then(result => {
      return {
        success:true,
        payload:result
      };
    }
  ).
  catch(e => {
    commonErrorHandling( e.networkError.result)
      return {
          success:false,
          payload:e
      };
  })
  
  return response;
    
}
