import { put, call, takeLatest } from 'redux-saga/effects';
import {
  FTP_CONNECTION_SUCCESSED,
  FTP_CONNECTION_FAILED,
  FTP_CONNECTION_REQUESTED,


} from '../../constant/actionTypes';
import  ftpConnectionSaga  from '../../apis/FTP/ftpConnection';


export  function*  watchFtpConnection(action) {
  try {
    const data = yield  call( ftpConnectionSaga, action.payload);
  
    if (data?.success && data?.success == true) {
      yield put({ type: FTP_CONNECTION_SUCCESSED, data: data?.payload?.data?.ftpconnection });
    } else {
      console.log( data.payload);
      yield put({ type: FTP_CONNECTION_FAILED, data: data.payload.message });
    }
  } catch (err) {
      console.log(err);
    yield put({ type: FTP_CONNECTION_FAILED, data: err?.response?.data?.data });
  }
}


export default function* watcher() {
  yield takeLatest(FTP_CONNECTION_REQUESTED, watchFtpConnection);

}