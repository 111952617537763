import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension'
import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import rootReducer from '../reducer/index';



import generalSaga from '../sagas/index';

const sagaMiddleware = createSagaMiddleware();
let middleware = applyMiddleware(sagaMiddleware)
if (process.env.REACT_ENV === 'prod') {
  middleware = applyMiddleware(sagaMiddleware,createLogger())
}
export const store = createStore(
  rootReducer,
  composeWithDevTools(middleware)
);

sagaMiddleware.run(generalSaga);

export default { store };
