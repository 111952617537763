
import {
    gql
  } from "@apollo/client";
  import { privateClient as client } from '..';
  import { commonErrorHandling } from '../Utils/index';
  
  
  export default function* requestServiceSaga(data) {
  
    const query = gql`
        mutation requestServiceItem($service_id:Int){
            requestService(service_id:$service_id) {
                message
            }
        }
    `;
  
    return yield client
      .mutate({
        mutation: query,
        variables:{
            service_id:data.service_id,
        }
      })
      .then(result => {
        return {
          success:true,
          payload:result
        };
      }
    ).
    catch(e => {
      commonErrorHandling( e.networkError.result)
        return {
            success:false,
            payload:e
        };
    });
      
  }
  