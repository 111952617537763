import {
    GET_PRODUCT_CUSTOMIZE_FAILED,
    GET_PRODUCT_CUSTOMIZE_PROCESSING,
    GET_PRODUCT_CUSTOMIZE_REQUESTED,
    GET_PRODUCT_CUSTOMIZE_SUCCESSED
} from '../../../constant/actionTypes';

const initialState = {
    data: {},
    isProcessing: null,
    isSuccess: null,
    hasErrors: null,
  };
  
  export const getCustomizeDetailsStore= (state = initialState, action) => {
    switch (action.type) {
        
      case GET_PRODUCT_CUSTOMIZE_REQUESTED: {
        return {
          ...state,
          result: null,
          error: null,
          loading: true,
          isSuccess: false,
          requested: true,
        };
      }
      case GET_PRODUCT_CUSTOMIZE_PROCESSING: {
        return {
          ...state,
          result: null,
          error: null,
          loading: true,
          isSuccess: false,
          requested: false,
        };
      }
      case GET_PRODUCT_CUSTOMIZE_SUCCESSED: {
        return {
          result: action.data,
          error: null,
          loading: false,
          isSuccess: true,
          requested: false,
        };
      }
      case GET_PRODUCT_CUSTOMIZE_FAILED: {
        return {
          result: null,
          error: action.data,
          loading: false,
          isSuccess: false,
          requested: false,
        };
      }
  
      default: {
        return {
          ...state,
        };
      }
    }
  };
  