
import {
    gql
  } from "@apollo/client";
  import { privateClient as client } from '..';
  import { commonErrorHandling } from '../Utils/index';
  
  
  export default function* changeProductsStatusSaga(data) {
  
    const query = gql`
    mutation changeProductsStatusItem($products:[Int],$status:Int,$isAll:Int){  
        changeProductsStatus(productIds:$products,status:$status,isAll:$isAll){
          status,
          message          
          }  
  }
    `;
  
    return yield client
      .mutate({
        mutation: query,
        variables:{
            products:data.products,
            status:data.status,
            isAll:data.isAll
        }
      })
      .then(result => {
        
        return {
          success:true,
          payload:result
        };
      }
    ).
    catch(e => {
      commonErrorHandling( e)
        return {
            success:false,
            payload:e
        };
    });
      
  }
  