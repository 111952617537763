import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';



function HeaderContainer({ setSliderVisible,sliderVisible }) {
 

  return (
    <header className="header-bar">
		<div className="d-flex">
			<div className="toggle-nav c-p" onClick={()=>{setSliderVisible(!sliderVisible)}}>
				<i className="fal fa-bars fa-2x"></i>
			</div>
		</div>
	</header>
  );
}

const mapStateToProps = state => {
  return {

  };
};

const mapDispatchToProps = dispatch => {
  return {
   
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderContainer);
