
import {
    gql
  } from "@apollo/client";
import { privateClient as client } from '..';
import { commonErrorHandling } from '../Utils/index';
export default function* ftpConnectionSaga(data) {
   

    const query = gql`
    query ftpconnectionItem($Host:String,$Username:String,$Password:String,$service_id:Int,$protocol:String){
        ftpconnection(Host:$Host,Port:21,Username:$Username,Password:$Password,service_id:$service_id,protocol:$protocol){
            message,
            success
        }
      }
    `;
    const response = yield client
      .query({
        query: query,
        variables:{
            Host:data.Host,
            Username:data.Username,
            Password:data.Password,
            service_id:data.service_id,
            protocol:data.protocol
        }

      })
      .then(result => {
        return {
            success:true,
            payload:result
        };
      }
    ).
    catch(e => {
     
      commonErrorHandling( e)
        return {
            success:false,
            payload:e
        };
    })
    
    return response;
      
}
