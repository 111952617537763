import React, { useState } from 'react';
import ErrorMessage from '../ErrorMessage';

function PasswordInputBox({  name, label, placeholder, onChange, value, onBlur, touched, error  }) {

    const [isPasswordType, setIsPasswordType] = useState(true);
    return (
        <>
            <label className="form-label">{label}</label>
            <div className="position-relative field-icon">
                <input 
                    type={isPasswordType?'password':'text'}
                    className="form-control" 
                    name={name}
                    onChange={onChange}
                    placeholder={placeholder}
                    value={value}
                    onBlur={onBlur}
                />
                <span className="show-password icon-form-field d-flex justify-content-center align-items-center trans3s c-p"
                    onClick={()=>setIsPasswordType(!isPasswordType)}
                >
                   {isPasswordType ?<i className="fa fa-eye"></i>:<i className="fa fa-eye-slash"></i>} 
                </span>
            </div>
            <ErrorMessage touched={touched} error={error} />
            
        </>
    );
}



export default PasswordInputBox;
