import React, { useState } from 'react';
import PropTypes from 'prop-types';
import HeaderContainer from './Header';
import SidebarContainer from './Sidebar';
import { useMediaQuery } from 'react-responsive'

const contentClass = 'content-wrap trans3s h-100 position-relative';
const collapsed = 'collapsed';

function LayoutContainer({ children,routes }) {
  
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
  const [sliderVisible, setSliderVisible] = useState(isTabletOrMobile);
  return (
    <div className="admin-wrap">
        <SidebarContainer sliderVisible={sliderVisible}  children={routes} setSliderVisible={setSliderVisible}/>
        <div className={sliderVisible?contentClass+' '+collapsed:contentClass}>
          <HeaderContainer setSliderVisible={setSliderVisible} sliderVisible={sliderVisible} />
          <section className="content-area">
            {children}
          </section>
        </div>
		</div>

   
  );
}

LayoutContainer.propTypes = {
  children: PropTypes.node,
};

export default LayoutContainer;
