
import {
  gql
} from "@apollo/client";
import { privateClient as client } from '..';
import { commonErrorHandling } from '../Utils/index';


export default function* serviceIntegrationSaga(data) {

  const query = gql`
      mutation serviceVendorIntegrationItem($metaKeys:[String!], $metaValues:[String!],$service_id:Int ){
        serviceVendorIntegration(metaKeys:$metaKeys,metaValues:$metaValues,service_id:$service_id) {
              message
          }
      }
  `;

  const response = yield client
    .mutate({
      mutation: query,
      variables:{
        metaKeys:data.metaKeys,
        metaValues:data.metaValues,
        service_id:data.service_id,
      }
    })
    .then(result => {
      return {
        success:true,
        payload:result
      };
    }
  ).
  catch(e => {
    commonErrorHandling( e)
      return {
          success:false,
          payload:e
      };
  })
  
  return response;
    
}
