
import './index.css';
import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom';
import { store } from './redux/store/index';
import PrivateRoute from './containers/PrivateRoute';
import RedirectPage from './containers/pages/RedirectPage';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import './assets/css/all-fw.css';
import './assets/css/style.css';
import './assets/css/responsive.css';
import './assets/css/custom.css';

import { ParallaxProvider } from 'react-scroll-parallax';
import Aboutus from './containers/pages/Aboutus';
import FAQs from './containers/pages/FAQs';


const history = createBrowserHistory();
const previousPath = history.location.pathname;

const App = lazy(() => import('./containers/App'));
const Login = lazy(() => import('./containers/pages/Login'));
const Registration = lazy(() => import('./containers/pages/Registration'));
const HomePage = lazy(() => import('./containers/pages/HomePage'));
const ForgotPassword = lazy(() => import('./containers/pages/ForgotPassword'));
const ResetPassword = lazy(() => import('./containers/pages/ResetPassword'));
const ServiceRequest = lazy(() => import('./containers/pages/ServiceRequest'));

ReactDOM.render(
<ParallaxProvider>
  <Provider store={store}>
    <Router history={history} basename={process.env.PUBLIC_URL}>
      <Suspense fallback={<RedirectPage />}>
        <Switch>
          <Route
            exact
            path="/login"
            render={props => <Login {...props} previousPath={previousPath} />}
          />
          <Route
            exact
            path="/register"
            render={props => <Registration {...props} previousPath={previousPath} />}
          />
          <Route
            exact
            path="/"
            render={props => <HomePage {...props} previousPath={previousPath} />}
          />
          <Route
            exact
            path="/forgot-password"
            render={props => <ForgotPassword {...props} previousPath={previousPath} />}
          />
          <Route
            exact
            path="/about-us"
            render={props => <Aboutus {...props} previousPath={previousPath} />}
          />

          <Route
            exact
            path="/faqs"
            render={props => <FAQs {...props} previousPath={previousPath} />}
          />
          <Route
            exact
            path="/reset-password"
            render={props => <ResetPassword {...props} previousPath={previousPath} />}
          />
          <Route
            exact
            path="/service-request"
            render={props => <ServiceRequest {...props} previousPath={previousPath} />}
          />
          <PrivateRoute path="/" component={App} />
        </Switch>
      </Suspense>
    </Router>
  </Provider>
  </ParallaxProvider>,
  document.getElementById('root')
);
