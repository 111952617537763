import {

    ISSERVICE_ACTIVE_REQUESTED,
    ISSERVICE_ACTIVE_PROCESSING,
    ISSERVICE_ACTIVE_SUCCESSED,
    ISSERVICE_ACTIVE_FAILED,
   
  
  } from '../../../constant/actionTypes';
   
  const initialState = {
    data: {},
    isProcessing: null,
    isSuccess: null,
    hasErrors: null,
  };
  
  export const isServiceActiveStore = (state = initialState, action) => {
    
    switch (action.type) {
    
      case ISSERVICE_ACTIVE_REQUESTED: {
        return {
          ...state,
          result: null,
          error: null,
          loading: true,
          isSuccess: false,
          requested: true,
        };
      }
      case ISSERVICE_ACTIVE_PROCESSING: {
        return {
          ...state,
          result: null,
          error: null,
          loading: true,
          isSuccess: false,
          requested: false,
        };
      }
      case ISSERVICE_ACTIVE_SUCCESSED: {
        return {
          result: action.data,
          error: null,
          loading: false,
          isSuccess: true,
          requested: false,
        };
      }
      case ISSERVICE_ACTIVE_FAILED: {
        return {
          result: null,
          error: action.data,
          loading: false,
          isSuccess: false,
          requested: false,
        };
      }
  
      default: {
        return {
          ...state,
        };
      }
    }
  };
  