import { put, call, takeLatest } from 'redux-saga/effects';
import {
  CATALOG_RULE_SUBMIT_SUCCESSED,
  CATALOG_RULE_SUBMIT_FAILED,
  CATALOG_RULE_SUBMIT_REQUESTED,

  GET_CATALOG_RULE_SUCCESSED,
  GET_CATALOG_RULE_FAILED,
  GET_CATALOG_RULE_REQUESTED,
} from '../../constant/actionTypes';
import  submitCatalogRuleSaga  from '../../apis/CatalogRule/submitCatalogRule';
import  getCatalogRuleSaga  from '../../apis/CatalogRule/getCatalogRule';

export  function*  watchSubmitCatalogRule(action) {
  try {
    
    const data = yield  call( submitCatalogRuleSaga, action.payload);
    
    if (data?.success && data?.success == true) {
      yield put({ type: CATALOG_RULE_SUBMIT_SUCCESSED, data: data?.payload?.data?.catalog_rule_submit });
    } else {
      console.log( data.payload);
      yield put({ type: CATALOG_RULE_SUBMIT_FAILED, data: data.payload.message });
    }
  } catch (err) {
      console.log(err);
    yield put({ type: CATALOG_RULE_SUBMIT_FAILED, data: err?.response?.data?.data });
  }
}

export  function*  watchGetCatalogRule(action) {
    try {
      
      const data = yield  call( getCatalogRuleSaga, action.payload);
      
      if (data?.success && data?.success == true) {
        yield put({ type: GET_CATALOG_RULE_SUCCESSED, data: data?.payload?.data?.get_catalog_rule });
      } else {
        console.log( data.payload);
        yield put({ type: GET_CATALOG_RULE_FAILED, data: data.payload.message });
      }
    } catch (err) {
        console.log(err);
      yield put({ type: GET_CATALOG_RULE_FAILED, data: err?.response?.data?.data });
    }
  }


export default function* watcher() {
  yield takeLatest(CATALOG_RULE_SUBMIT_REQUESTED, watchSubmitCatalogRule);
  yield takeLatest(GET_CATALOG_RULE_REQUESTED, watchGetCatalogRule);
}