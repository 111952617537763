import { combineReducers } from "redux";

/* auth */
import { loginStore } from './Auth/login';
import { forgotPasswordStore } from './Auth/forgotpassword';
import { resetPasswordStore } from './Auth/resetpassword';
import { logoutStore } from './Auth/logout';
import { profileUploadStore } from './Auth/profileUpload';
import { registerStore } from './Auth/register';
/* service */
import { serviceListStore } from './Service/list';
import { serviceDetailsStore } from './Service/details';
import { approvedServiceListStore } from './Service/approvedList';
import { serviceRequestStore } from "./Service/requestSave";
import { serviceAuthFieldsStore } from "./Service/serviceAuthFields";
import { serviceIntegrationStore } from "./Service/serviceIntegration";
import { isServiceActiveStore } from "./Service/isServiceActive";
import { updateServiceStatusStore } from "./Service/updateServiceStatus";
import { requestServiceStore } from "./Service/requestService";
import { crowServiceStore } from "./Service/crowService";

import { productListStore } from "./Product/list";
import { changeProductStatusStore } from "./Product/changeProductStatus";
import { customizeStore } from "./Product/customize";
import { changePriceStore, removeChangePriceStore } from "./Product/changePrice";
import { productImagesStore } from "./Product/productImages";
import { removeProductImagesStore } from "./Product/removeProductImage";
import { uploadProductImagesStore } from "./Product/uploadProductImage";
import { viewAppliedCatalogRuleStore } from "./Product/viewAppliedCatalogRule";
import { getCustomizeDetailsStore } from "./Product/getCustomizeDetails";

import { advanceSearchAttributesStore } from "./Attribute/advanceSearchAttributes";
import { attributeFilterListStore } from "./Attribute/attributefilterlist";
import { attributeListStore } from "./Attribute/attributeList";
import { categoryFilterlistStore } from "./Category/categoryFilterlist";
import { settingsStore } from "./Setting/settings";

import {ftpConnectionStore} from './FTP/ftpConnection';
import { submitUserMetaStore } from './User/submitUserMeta';
import {getUserDataStore} from './User/getUserData';
import { isWdAuthIntegratedStore } from './User/isWdAuthIntegrated';
import { submitCatalogRuleStore } from './CatalogRule/submitCatalogRule';
import { getCatalogRuleStore } from './CatalogRule/getCatalogRule';
import { dashboardStore } from './Service/dashboard';

const appReducer = combineReducers(
  { 
    loginStore,
    logoutStore,
    forgotPasswordStore,
    resetPasswordStore,
    registerStore,

    serviceListStore,
    serviceRequestStore,
    approvedServiceListStore,
    serviceDetailsStore,
    serviceAuthFieldsStore,
    serviceIntegrationStore,
    isServiceActiveStore,
    updateServiceStatusStore,
    requestServiceStore,
    crowServiceStore,

    productListStore,
    changeProductStatusStore,
    changePriceStore,
    customizeStore,
    removeChangePriceStore,
    productImagesStore,
    removeProductImagesStore,
    uploadProductImagesStore,
    viewAppliedCatalogRuleStore,
    getCustomizeDetailsStore,
    
    advanceSearchAttributesStore,
    attributeFilterListStore,
    attributeListStore,
    categoryFilterlistStore,
    ftpConnectionStore,
    submitUserMetaStore,
    isWdAuthIntegratedStore,
    submitCatalogRuleStore,
    getCatalogRuleStore,
    dashboardStore,
    profileUploadStore,
    getUserDataStore,
    settingsStore


  });

const rootReducer = (state, action) => {
  if (action.type === "RESETSTATE_REQUESTED") {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
