
import {
    gql
  } from "@apollo/client";
  import { privateClient as client } from '..';
  import { commonErrorHandling } from '../Utils/index';
  
  
  export default function* submitUserMeta(data) {
  
    const query = gql`
        mutation submitUserMeta($consumer_key:String!, $consumer_secret:String! ){
            submitUserMeta(consumer_key:$consumer_key,consumer_secret:$consumer_secret) {
                message,status
            }
        }
    `;
  
    const response = yield client
      .mutate({
        mutation: query,
        variables:{
          consumer_key:data.consumer_key,
          consumer_secret:data.consumer_secret,
        }
      })
      .then(result => {
        return {
          success:true,
          payload:result
        };
      }
    ).
    catch(e => {
      commonErrorHandling( e)
        return {
            success:false,
            payload:e
        };
    })
    
    return response;
      
  }
  