import {
    CHANGE_PRICE_REQUESTED,
    CHANGE_PRICE_PROCESSING,
    CHANGE_PRICE_SUCCESSED,
    CHANGE_PRICE_FAILED,

    REMOVE_CHANGE_PRICE_REQUESTED,
    REMOVE_CHANGE_PRICE_PROCESSING,
    REMOVE_CHANGE_PRICE_SUCCESSED,
    REMOVE_CHANGE_PRICE_FAILED
} from '../../../constant/actionTypes';

const initialState = {
    data: {},
    isProcessing: null,
    isSuccess: null,
    hasErrors: null,
  };
  
  export const changePriceStore= (state = initialState, action) => {
    switch (action.type) {
        
      case CHANGE_PRICE_REQUESTED: {
        return {
          ...state,
          result: null,
          error: null,
          loading: true,
          isSuccess: false,
          requested: true,
        };
      }
      case CHANGE_PRICE_PROCESSING: {
        return {
          ...state,
          result: null,
          error: null,
          loading: true,
          isSuccess: false,
          requested: false,
        };
      }
      case CHANGE_PRICE_SUCCESSED: {
        return {
          result: action.data,
          error: null,
          loading: false,
          isSuccess: true,
          requested: false,
        };
      }
      case CHANGE_PRICE_FAILED: {
        return {
          result: null,
          error: action.data,
          loading: false,
          isSuccess: false,
          requested: false,
        };
      }
  
      default: {
        return {
          ...state,
        };
      }
    }
  };

  export const removeChangePriceStore= (state = initialState, action) => {
    switch (action.type) {
        
      case REMOVE_CHANGE_PRICE_REQUESTED: {
        return {
          ...state,
          result: null,
          error: null,
          loading: true,
          isSuccess: false,
          requested: true,
        };
      }
      case REMOVE_CHANGE_PRICE_PROCESSING: {
        return {
          ...state,
          result: null,
          error: null,
          loading: true,
          isSuccess: false,
          requested: false,
        };
      }
      case REMOVE_CHANGE_PRICE_SUCCESSED: {
        return {
          result: action.data,
          error: null,
          loading: false,
          isSuccess: true,
          requested: false,
        };
      }
      case REMOVE_CHANGE_PRICE_FAILED: {
        return {
          result: null,
          error: action.data,
          loading: false,
          isSuccess: false,
          requested: false,
        };
      }
  
      default: {
        return {
          ...state,
        };
      }
    }
  };
  