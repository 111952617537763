import React, { useEffect, useState } from 'react'
import ImageBox from '../ImageBox' ;


function LoginWrap({ title,children,logo,inline,type,className }) {
 
  return (
    <div className= {className? className:"login-wrap ms-auto me-auto"}>
        <div className="row justify-content-center">
            <div className={inline?"col-12 d-flex":"col-12"} style={{textAlign: 'center'}}>
                <div className="panel-logo">
                    <a href="/">
                        <ImageBox width="50" src={logo} className="img-fluid" alt="cloud" />
                    </a>
                </div>
                <h3 className={inline ? "inline-h3":'no-class'}>{title}</h3>
               
            </div>
            <div className="col-12">{children}</div>
            
        </div>
    </div>
  );
}



export default LoginWrap;
