import {

  SERVICE_REQUEST_SAVE_REQUESTED,
  SERVICE_REQUEST_SAVE_PROCESSING,
  SERVICE_REQUEST_SAVE_SUCCESSED,
  SERVICE_REQUEST_SAVE_FAILED,
 

} from '../../../constant/actionTypes';
 
const initialState = {
  data: {},
  isProcessing: null,
  isSuccess: null,
  hasErrors: null,
};

export const serviceRequestStore = (state = initialState, action) => {
  
  switch (action.type) {
  
    case SERVICE_REQUEST_SAVE_REQUESTED: {
      return {
        ...state,
        result: null,
        error: null,
        loading: true,
        isSuccess: false,
        requested: true,
      };
    }
    case SERVICE_REQUEST_SAVE_PROCESSING: {
      return {
        ...state,
        result: null,
        error: null,
        loading: true,
        isSuccess: false,
        requested: false,
      };
    }
    case SERVICE_REQUEST_SAVE_SUCCESSED: {
      return {
        result: action.data,
        error: null,
        loading: false,
        isSuccess: true,
        requested: false,
      };
    }
    case SERVICE_REQUEST_SAVE_FAILED: {
      return {
        result: null,
        error: action.data,
        loading: false,
        isSuccess: false,
        requested: false,
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
};
