import {
    REMOVE_PRODUCT_IMAGES_REQUESTED,
    REMOVE_PRODUCT_IMAGES_PROCESSING,
    REMOVE_PRODUCT_IMAGES_SUCCESSED,
    REMOVE_PRODUCT_IMAGES_FAILED
} from '../../../constant/actionTypes';

const initialState = {
    data: {},
    isProcessing: null,
    isSuccess: null,
    hasErrors: null,
  };
  
  export const removeProductImagesStore= (state = initialState, action) => {
    switch (action.type) {
        
      case REMOVE_PRODUCT_IMAGES_REQUESTED: {
        return {
          ...state,
          result: null,
          error: null,
          loading: true,
          isSuccess: false,
          requested: true,
        };
      }
      case REMOVE_PRODUCT_IMAGES_PROCESSING: {
        return {
          ...state,
          result: null,
          error: null,
          loading: true,
          isSuccess: false,
          requested: false,
        };
      }
      case REMOVE_PRODUCT_IMAGES_SUCCESSED: {
        return {
          result: action.data,
          error: null,
          loading: false,
          isSuccess: true,
          requested: false,
        };
      }
      case REMOVE_PRODUCT_IMAGES_FAILED: {
        return {
          result: null,
          error: action.data,
          loading: false,
          isSuccess: false,
          requested: false,
        };
      }
  
      default: {
        return {
          ...state,
        };
      }
    }
  };
  