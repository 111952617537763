import { put, call, takeLatest } from 'redux-saga/effects';
import {


  CATEGORY_FILTER_LIST_SUCCESSED,
  CATEGORY_FILTER_LIST_FAILED,
  CATEGORY_FILTER_LIST_REQUESTED,

} from '../../constant/actionTypes';


import  categoryFilterListSaga  from '../../apis/Category/categoryFilterList';




export  function*  watchCategoryFilterList(action) {
  try {
    
    const data = yield  call( categoryFilterListSaga, action.payload);
    
    if (data?.success && data?.success == true) {
      yield put({ type: CATEGORY_FILTER_LIST_SUCCESSED, data: data?.payload?.data?.categoriesAll });
    } else {
      console.log( data.payload);
      yield put({ type: CATEGORY_FILTER_LIST_FAILED, data: data.payload.message });
    }
  } catch (err) {
      console.log(err);
    yield put({ type: CATEGORY_FILTER_LIST_FAILED, data: err?.response?.data?.data });
  }
}


export default function* watcher() {

  yield takeLatest(CATEGORY_FILTER_LIST_REQUESTED, watchCategoryFilterList);

}