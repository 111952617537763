import {  LOGOUT_REQUESTED,RESETSTATE_REQUESTED,PROFILE_UPLOAD_REQUESTED } from '../../constant/actionTypes';


export  function userLogout(payload) {
  
  return {
    type: LOGOUT_REQUESTED,
    payload,
  };
}

export  function resetAllState(payload) {
  
  return {
    type: RESETSTATE_REQUESTED,
    payload,
  };
}

export  function profileUpload(payload,callback) {
  return {
    type: PROFILE_UPLOAD_REQUESTED,
    payload,callback
  };
}




