import { put, call, takeLatest } from 'redux-saga/effects';
import {
  PRODUCT_LIST_SUCCESSED,
  PRODUCT_LIST_FAILED,
  PRODUCT_LIST_REQUESTED,

  CHANGE_PRODUCT_STATUS_SUCCESSED,
  CHANGE_PRODUCT_STATUS_FAILED,
  CHANGE_PRODUCT_STATUS_REQUESTED,

  CHANGE_PRICE_SUCCESSED,
  CHANGE_PRICE_FAILED,
  CHANGE_PRICE_REQUESTED,

  REMOVE_CHANGE_PRICE_REQUESTED,
  REMOVE_CHANGE_PRICE_SUCCESSED,
  REMOVE_CHANGE_PRICE_FAILED,

  VIEW_APPLIED_CATALOG_RULE_SUCCESSED,
  VIEW_APPLIED_CATALOG_RULE_FAILED,
  VIEW_APPLIED_CATALOG_RULE_REQUESTED,

  PRODUCT_CUSTOMIZE_FAILED,
  PRODUCT_CUSTOMIZE_REQUESTED,
  PRODUCT_CUSTOMIZE_SUCCESSED,

  PRODUCT_IMAGES_REQUESTED,
  PRODUCT_IMAGES_SUCCESSED,
  PRODUCT_IMAGES_FAILED,

  REMOVE_PRODUCT_IMAGES_REQUESTED,
  REMOVE_PRODUCT_IMAGES_SUCCESSED,
  REMOVE_PRODUCT_IMAGES_FAILED,

  UPLOAD_PRODUCT_IMAGES_REQUESTED,
  UPLOAD_PRODUCT_IMAGES_SUCCESSED,
  UPLOAD_PRODUCT_IMAGES_FAILED,

  GET_PRODUCT_CUSTOMIZE_REQUESTED,
  GET_PRODUCT_CUSTOMIZE_SUCCESSED,
  GET_PRODUCT_CUSTOMIZE_FAILED

} from '../../constant/actionTypes';
import  productListSaga  from '../../apis/Product/list';
import changeProductsStatusSaga from '../../apis/Product/changeProductStatus';
import changePriceSaga from '../../apis/Product/changePrice';
import removeChangePriceSaga from '../../apis/Product/removeChangePrice';
import viewAppliedCatalogRuleSaga from '../../apis/Product/viewAppliedCatalogRule';
import customizeSaga from '../../apis/Product/customize';
import productImagesSaga from '../../apis/Product/productImages';
import getCustomizeDetailsSaga from '../../apis/Product/getCustomizeDetails';
import removeProductImageSaga from '../../apis/Product/removeProductImage';
import uploadProductImageSaga from '../../apis/Product/uploadProductImage';

export  function*  watchProductList(action) {
  try {
    
    const data = yield  call( productListSaga, action.payload);
    
    if (data?.success && data?.success == true) {
      yield put({ type: PRODUCT_LIST_SUCCESSED, data: data?.payload?.data?.productList });
    } else {
      console.log( data.payload);
      yield put({ type: PRODUCT_LIST_FAILED, data: data.payload.message });
    }
  } catch (err) {
      console.log(err);
    yield put({ type: PRODUCT_LIST_FAILED, data: err?.response?.data?.data });
  }
}

export function* watchChangeProductStatus(action){
  try{
    const data = yield call(changeProductsStatusSaga, action.payload);
    if (data?.success && data?.success === true) {
      action.callback();
      yield put({ type: CHANGE_PRODUCT_STATUS_SUCCESSED, data: data?.payload?.data?.changeProductsStatus });
    } else {
      console.log( data.payload);
      yield put({ type: CHANGE_PRODUCT_STATUS_FAILED, data: data.payload.message });
    }
  }
  catch (err) {
    console.log(err);
  yield put({ type: CHANGE_PRODUCT_STATUS_FAILED, data: err?.response?.data?.data });
}
}

export function* watchChangePrice(action){
  try{
    const data = yield call(changePriceSaga, action.payload);
    if(data?.success && data?.success == true) {
      action.callback();
      yield put({ type: CHANGE_PRICE_SUCCESSED, data: data?.payload?.data?.catalog_product_rule_submit });
    } else {
      console.log( data.payload );
      yield put({ type: CHANGE_PRICE_FAILED, data: data.payload.message });
    }
  }
  catch(err) {
    console.log(err);
    yield put({ type: CHANGE_PRICE_FAILED, data: err?.response?.data?.data })
  }
}

export function* watchRemoveChangePrice(action){
  try{
    const data = yield call(removeChangePriceSaga, action.payload);
    if(data?.success && data?.success == true) {
      yield put({ type: REMOVE_CHANGE_PRICE_SUCCESSED, data: data?.payload?.data?.remove_catalog_rule });
    } else {
      console.log( data.payload );
      yield put({ type: REMOVE_CHANGE_PRICE_FAILED, data: data.payload.message });
    }
  }
  catch(err) {
    console.log(err);
    yield put({ type: REMOVE_CHANGE_PRICE_FAILED, data: err?.response?.data?.data })
  }
}

export function* watchViewAppliedCatalogRule(action){
  try{
    const data = yield call(viewAppliedCatalogRuleSaga, action.payload);
    if(data?.success && data?.success == true){
      yield put({type: VIEW_APPLIED_CATALOG_RULE_SUCCESSED, data: data?.payload?.data?.get_product_catalog_rule});
    } else {
      console.log( data.payload );
      yield put({ type: VIEW_APPLIED_CATALOG_RULE_FAILED, data: data.payload.message})
    }
  }
  catch(err) {
    console.log(err);
    yield put({ type: VIEW_APPLIED_CATALOG_RULE_FAILED, data: err?.response?.data?.data })
  }
}

export function* watchCustomize(action){
  try{
    const data = yield call(customizeSaga, action.payload);
    if(data?.success && data?.success == true){
      action.callback();
      yield put({type: PRODUCT_CUSTOMIZE_SUCCESSED, data: data?.payload?.data?.updateProduct});
    } else {
      console.log( data.payload );
      yield put({ type: PRODUCT_CUSTOMIZE_FAILED, data: data.payload.message})
    }
  }
  catch(err) {
    console.log(err);
    yield put({ type: PRODUCT_CUSTOMIZE_FAILED, data: err?.response?.data?.data })
  }
}

export function* watchProductImages(action){
  try{
    const data = yield call(productImagesSaga, action.payload);
    if(data?.success && data?.success == true){
      yield put({type: PRODUCT_IMAGES_SUCCESSED, data: data?.payload?.data?.getProductGalleries});
    } else {
      console.log( data.payload );
      yield put({ type: PRODUCT_IMAGES_FAILED, data: data.payload.message})
    }
  }
  catch(err) {
    console.log(err);
    yield put({ type: PRODUCT_IMAGES_FAILED, data: err?.response?.data?.data })
  }
}

export function* watchRemoveProductImages(action){
  try{
    const data = yield call(removeProductImageSaga, action.payload);
    if(data?.success && data?.success == true){
      action.callback();
      yield put({type: REMOVE_PRODUCT_IMAGES_SUCCESSED, data: data?.payload?.data?.removeProductImage});
    } else {
      console.log( data.payload );
      yield put({ type: REMOVE_PRODUCT_IMAGES_FAILED, data: data.payload.message})
    }
  }
  catch(err) {
    console.log(err);
    yield put({ type: REMOVE_PRODUCT_IMAGES_FAILED, data: err?.response?.data?.data })
  }
}

export function* watchUploadProductImages(action){
  try{
    const data = yield call(uploadProductImageSaga, action.payload);
    
    action.callback();
    if(data?.data?.uploadProductImageSaga?.status){
    
      yield put({type: UPLOAD_PRODUCT_IMAGES_SUCCESSED, data: data?.data?.uploadProductImageSaga});
      
    } else {
      console.log( data.payload );
      yield put({ type: UPLOAD_PRODUCT_IMAGES_FAILED, data: 'Error'})
    }
  }
  catch(err) {
    console.log(err);
    yield put({ type: UPLOAD_PRODUCT_IMAGES_FAILED, data: err?.response?.data?.data })
  }
}

export function* watchGetCustomizeDetails(action){
  try{
    const data = yield call(getCustomizeDetailsSaga, action.payload);
    if(data?.success && data?.success == true){
      yield put({type: GET_PRODUCT_CUSTOMIZE_SUCCESSED, data: data?.payload?.data?.getProductDetails});
    } else {
      console.log( data.payload );
      yield put({ type: GET_PRODUCT_CUSTOMIZE_FAILED, data: data.payload.message})
    }
  }
  catch(err) {
    console.log(err);
    yield put({ type: GET_PRODUCT_CUSTOMIZE_FAILED, data: err?.response?.data?.data })
  }
}

export default function* watcher() {
  yield takeLatest(PRODUCT_LIST_REQUESTED, watchProductList);
  yield takeLatest(CHANGE_PRODUCT_STATUS_REQUESTED, watchChangeProductStatus);
  yield takeLatest(CHANGE_PRICE_REQUESTED, watchChangePrice);
  yield takeLatest(REMOVE_CHANGE_PRICE_REQUESTED, watchRemoveChangePrice);
  yield takeLatest(VIEW_APPLIED_CATALOG_RULE_REQUESTED, watchViewAppliedCatalogRule);
  yield takeLatest(PRODUCT_CUSTOMIZE_REQUESTED, watchCustomize);
  yield takeLatest(PRODUCT_IMAGES_REQUESTED, watchProductImages);
  yield takeLatest(REMOVE_PRODUCT_IMAGES_REQUESTED, watchRemoveProductImages);
  yield takeLatest(UPLOAD_PRODUCT_IMAGES_REQUESTED, watchUploadProductImages);
  yield takeLatest(GET_PRODUCT_CUSTOMIZE_REQUESTED, watchGetCustomizeDetails);
}