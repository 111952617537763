
import {
    gql
  } from "@apollo/client";
import { privateClient as client } from '..';
import { commonErrorHandling } from '../Utils/index';
export default function* serviceDetailsSaga(data) {
   

    const query = gql`
    query serviceDetailsItem($id:String){
        serviceDetails(id:$id) {
            id,
            name,
            login_via,
            logo,
            website_url,
            requestStatus,
            isApproved,
            contact_link,
            api_auth_url,
            minimum_order,
            policy_link,
            allow_amazon,
            allow_ebay,
            catalog_type,
            is_integrated,
            large_description,
            ship_from_country,
            ship_to_country,
            coming_soon,
            integration_support,
            categories{
              id,
              name
            }
            status
          }
      }
    `;
    const response = yield client
      .query({
        query: query,
        variables:{
            id:data.id,
          },
          fetchPolicy: 'no-cache',

      })
      .then(result => {
        return {
            success:true,
            payload:result
        };
      }
    ).
    catch(e => {
     console.log(e);
      commonErrorHandling( e)
        return {
            success:false,
            payload:e
        };
    })
    
    return response;
      
}
