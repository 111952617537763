import {
    gql
} from "@apollo/client";
import { privateClient as client } from '..';
import { commonErrorHandling } from '../Utils/index';

export default function* viewAppliedCatalogRuleSaga(data) {
    const query = gql`  
    query get_product_catalog_ruleItem(
        $product_id:Int,
      ){
       get_product_catalog_rule(
             product_id: $product_id,
     ){
             id,
             service_id,
             product_id,
             markup_type,
             markup_amount,
             price,
             categories{
                   id,
                   name
             },
             sku,
             map,
             final_price,
             msrp,
             upc
       }      
   }`;

    return yield client
        .query({
            query: query,
            variables: {
                product_id: data.product_id,
            },
            fetchPolicy: 'no-cache'
        })
        .then(result => {
            return {
                success: true,
                payload: result
            };
        }
        ).
        catch(e => {
            commonErrorHandling(e)
            return {
                success: false,
                payload: e
            };
        });
}