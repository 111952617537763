import { put, call, takeLatest } from 'redux-saga/effects';
import {
  ATTRIBUTES_ADVANCE_SEARCH_SUCCESSED,
  ATTRIBUTES_ADVANCE_SEARCH_FAILED,
  ATTRIBUTES_ADVANCE_SEARCH_REQUESTED,

  ATTRIBUTES_FILTER_LIST_SUCCESSED,
  ATTRIBUTES_FILTER_LIST_FAILED,
  ATTRIBUTES_FILTER_LIST_REQUESTED,

  ATTRIBUTES_LIST_SUCCESSED,
  ATTRIBUTES_LIST_FAILED,
  ATTRIBUTES_LIST_REQUESTED,

} from '../../constant/actionTypes';

import  advanceSearchAttributesSaga  from '../../apis/Attribute/advanceSearchAttributes';
import  attributefilterlistSaga  from '../../apis/Attribute/attributefilterlist';


export  function*  watchAdvanceSearchAttributes(action) {
  try {
    
    const data = yield  call( advanceSearchAttributesSaga, action.payload);
    
    if (data?.success && data?.success == true) {
      yield put({ type: ATTRIBUTES_ADVANCE_SEARCH_SUCCESSED, data: data?.payload?.data?.advanceSearchAttributes });
    } else {
      console.log( data.payload);
      yield put({ type: ATTRIBUTES_ADVANCE_SEARCH_FAILED, data: data.payload.message });
    }
  } catch (err) {
      console.log(err);
    yield put({ type: ATTRIBUTES_ADVANCE_SEARCH_FAILED, data: err?.response?.data?.data });
  }
}

export  function*  watchAttributeFilterList(action) {
  try {
    
    const data = yield  call( attributefilterlistSaga, action.payload);
    
    if (data?.success && data?.success == true) {
      yield put({ type: ATTRIBUTES_FILTER_LIST_SUCCESSED, data: data?.payload?.data?.attributesAll });
    } else {
      console.log( data.payload);
      yield put({ type: ATTRIBUTES_FILTER_LIST_FAILED, data: data.payload.message });
    }
  } catch (err) {
      console.log(err);
    yield put({ type: ATTRIBUTES_FILTER_LIST_FAILED, data: err?.response?.data?.data });
  }
}

export  function*  watchAttributeList(action) {
  try {
    
    const data = yield  call( attributefilterlistSaga, action.payload);
    
    if (data?.success && data?.success == true) {
      yield put({ type: ATTRIBUTES_LIST_SUCCESSED, data: data?.payload?.data?.attributesAll });
    } else {
      console.log( data.payload);
      yield put({ type: ATTRIBUTES_LIST_FAILED, data: data.payload.message });
    }
  } catch (err) {
      console.log(err);
    yield put({ type: ATTRIBUTES_LIST_FAILED, data: err?.response?.data?.data });
  }
}

export default function* watcher() {
  yield takeLatest(ATTRIBUTES_ADVANCE_SEARCH_REQUESTED, watchAdvanceSearchAttributes);
  yield takeLatest(ATTRIBUTES_FILTER_LIST_REQUESTED, watchAttributeFilterList);
  yield takeLatest(ATTRIBUTES_LIST_REQUESTED, watchAttributeList);

}