
import API from '../../constant'
import {
    ApolloClient,
    InMemoryCache,
    ApolloProvider,
    useQuery,
    gql,
    createHttpLink
  } from "@apollo/client";
import { privateClient as client } from '..';
import { commonErrorHandling } from '../Utils/index';

export default function* approvedListSaga(data) {
   
    const servicesList = gql`
    query approvedServicesItem($key:String,$is_integrated:Boolean!){
            approvedServices(key:$key,is_integrated:$is_integrated){
              isApproved,       
            id,
            name,
            logo,
            ship_from_country,
            products,
            slug,
            requestStatus,
            is_integrated,
            categories{
                id,
                name
            }
        }
        }
    `;
    const response = yield client
      .query({
        query: servicesList,
        variables:{
          key:data.key,
          is_integrated:data?.is_integrated?data?.is_integrated:false
        },
        fetchPolicy: 'no-cache',
      })
      .then(result => {
        return {
            success:true,
            payload:result
        };
      }
    ).
    catch(e => {
      
        commonErrorHandling( e)
        return {
            success:false,
            payload:e
        };
    })
    
    return response;
      
}
