import { put, call, takeLatest } from 'redux-saga/effects';
import {
  SERVICE_LIST_SUCCESSED,
  SERVICE_LIST_FAILED,
  SERVICE_LIST_REQUESTED,

  SERVICE_DETAILS_SUCCESSED,
  SERVICE_DETAILS_FAILED,
  SERVICE_DETAILS_REQUESTED,

  SERVICE_AUTH_FIELDS_SUCCESSED,
  SERVICE_AUTH_FIELDS_FAILED,
  SERVICE_AUTH_FIELDS_REQUESTED,

  SERVICE_APPROVED_LIST_SUCCESSED,
  SERVICE_APPROVED_LIST_FAILED,
  SERVICE_APPROVED_LIST_REQUESTED,

  SERVICE_REQUEST_SAVE_SUCCESSED,
  SERVICE_REQUEST_SAVE_FAILED,
  SERVICE_REQUEST_SAVE_REQUESTED,

  SERVICE_VENDOR_INTEGRATION_REQUESTED,
  SERVICE_VENDOR_INTEGRATION_FAILED,
  SERVICE_VENDOR_INTEGRATION_SUCCESSED,

  DASHBOARD_REQUESTED,
  DASHBOARD_FAILED,
  DASHBOARD_SUCCESSED,

  ISSERVICE_ACTIVE_REQUESTED,
  ISSERVICE_ACTIVE_SUCCESSED,
  ISSERVICE_ACTIVE_FAILED,

  UPDATESERVICE_STATUS_REQUESTED,
  UPDATESERVICE_STATUS_SUCCESSED,
  UPDATESERVICE_STATUS_FAILED,

  REQUEST_SERVICE_REQUESTED,
  REQUEST_SERVICE_SUCCESSED,
  REQUEST_SERVICE_FAILED,

  CROW_SERVICE_REQUESTED,
  CROW_SERVICE_SUCCESSED,
  CROW_SERVICE_FAILED

} from '../../constant/actionTypes';
import  listServiceSaga  from '../../apis/Service/list';
import  serviceDetailsSaga  from '../../apis/Service/details';
import  approvedListSaga  from '../../apis/Service/approvedList';
import  serviceRequestSaga  from '../../apis/Service/requestSave';
import  serviceAuthFieldsSaga  from '../../apis/Service/serviceAuthFields';
import serviceIntegrationSaga from '../../apis/Service/serviceIntegration';
import dashboardSaga from '../../apis/Service/dashboard';
import isServiceActiveSaga from '../../apis/Service/isServiceActive';
import updateServiceStatusSaga from '../../apis/Service/updateServiceStatus';
import requestServiceSaga from '../../apis/Service/requestService';
import crowServiceIntegrationSaga from '../../apis/Service/crowService';

export  function*  watchServiceList(action) {
  try {
    const data = yield  call( listServiceSaga, action.payload);
  
    if (data?.success && data?.success == true) {
      yield put({ type: SERVICE_LIST_SUCCESSED, data: data?.payload?.data?.services });
    } else {
      console.log( data.payload);
      yield put({ type: SERVICE_LIST_FAILED, data: data.payload.message });
    }
  } catch (err) {
      console.log(err);
    yield put({ type: SERVICE_LIST_FAILED, data: err?.response?.data?.data });
  }
}

export  function*  watchDashboard(action) {
  try {
    const data = yield  call( dashboardSaga, action.payload);
  
    if (data?.success && data?.success == true) {
      yield put({ type: DASHBOARD_SUCCESSED, data: data?.payload?.data?.dashboard });
    } else {
      console.log( data.payload);
      yield put({ type: DASHBOARD_FAILED, data: data.payload.message });
    }
  } catch (err) {
      console.log(err);
    yield put({ type: DASHBOARD_FAILED, data: err?.response?.data?.data });
  }
}

export  function*  watchServiceDetailsList(action) {
  try {
    const data = yield  call( serviceDetailsSaga, action.payload);
  
    if (data?.success && data?.success == true) {
      yield put({ type: SERVICE_DETAILS_SUCCESSED, data: data?.payload?.data?.serviceDetails });
    } else {
  
      yield put({ type: SERVICE_DETAILS_FAILED, data: data.payload.message });
    }
  } catch (err) {
      console.log(err);
    yield put({ type: SERVICE_DETAILS_FAILED, data: err?.response?.data?.data });
  }
}


export  function*  watchServiceApprovedList(action) {
  try {
    const data = yield  call( approvedListSaga, action.payload);
  
    if (data?.success && data?.success == true) {
      yield put({ type: SERVICE_APPROVED_LIST_SUCCESSED, data: data?.payload?.data?.approvedServices });
    } else {
  
      yield put({ type: SERVICE_APPROVED_LIST_FAILED, data: data.payload.message });
    }
  } catch (err) {
      console.log(err);
    yield put({ type: SERVICE_APPROVED_LIST_FAILED, data: err?.response?.data?.data });
  }
}

export  function*  watchServiceRequestList(action) {
  try {
    const data = yield  call( serviceRequestSaga, action.payload);
  
    if (data?.success && data?.success == true) {
      yield put({ type: SERVICE_REQUEST_SAVE_SUCCESSED, data: data?.payload?.data?.services });
    } else {
   
      yield put({ type: SERVICE_REQUEST_SAVE_FAILED, data: data.payload.message });
    }
  } catch (err) {
      console.log(err);
    yield put({ type: SERVICE_REQUEST_SAVE_FAILED, data: err?.response?.data?.data });
  }
}


export  function* watchServiceAuthFieldsList(action) {
  try {
    const data = yield  call( serviceAuthFieldsSaga, action.payload);
  
    if (data?.success && data?.success == true) {
      yield put({ type: SERVICE_AUTH_FIELDS_SUCCESSED, data: data?.payload?.data?.service_auths_by_service });
    } else {
  
      yield put({ type: SERVICE_AUTH_FIELDS_FAILED, data: data.payload.message });
    }
  } catch (err) {
      console.log(err);
    yield put({ type: SERVICE_AUTH_FIELDS_FAILED, data: err?.response?.data?.data });
  }
}

export  function*  watchServiceIntegration(action) {
  try {
    const data = yield  call( serviceIntegrationSaga, action.payload);
  
    if (data?.success && data?.success == true) {
      yield put({ type: SERVICE_VENDOR_INTEGRATION_SUCCESSED, data: data?.payload?.data?.serviceVendorIntegration });
    } else {
  
      yield put({ type: SERVICE_VENDOR_INTEGRATION_FAILED, data: data.payload.message });
    }
  } catch (err) {
      console.log(err);
    yield put({ type: SERVICE_VENDOR_INTEGRATION_FAILED, data: err?.response?.data?.data });
  }
}

export  function*  watchIsServiceActive(action) {
  try {
    const data = yield  call( isServiceActiveSaga, action.payload);
  
    if (data?.success && data?.success == true) {
      yield put({ type: ISSERVICE_ACTIVE_SUCCESSED, data: data?.payload?.data?.isServiceActive });
    } else {
  
      yield put({ type: ISSERVICE_ACTIVE_FAILED, data: data.payload.message });
    }
  } catch (err) {
      console.log(err);
    yield put({ type: ISSERVICE_ACTIVE_FAILED, data: err?.response?.data?.data });
  }
}

export  function*  watchUpdateServiceStatus(action) {
  try {
    const data = yield  call( updateServiceStatusSaga, action.payload);
  
    if (data?.success && data?.success == true) {
      yield put({ type: UPDATESERVICE_STATUS_SUCCESSED, data: data?.payload?.data?.updateServiceStatus });
    } else {
  
      yield put({ type: UPDATESERVICE_STATUS_FAILED, data: data.payload.message });
    }
  } catch (err) {
      console.log(err);
    yield put({ type: UPDATESERVICE_STATUS_FAILED, data: err?.response?.data?.data });
  }
}

export  function*  watchRequestService(action) {
  try {
    const data = yield  call( requestServiceSaga, action.payload);
  
    if (data?.success && data?.success == true) {
      action.callback()
      yield put({ type: REQUEST_SERVICE_SUCCESSED, data: data?.payload?.data?.requestService });
    } else {
  
      yield put({ type: REQUEST_SERVICE_FAILED, data: data.payload.message });
    }
  } catch (err) {
      console.log(err);
    yield put({ type: REQUEST_SERVICE_FAILED, data: err?.response?.data?.data });
  }
}

export  function*  watchCrowService(action) {
  try {
    const data = yield  call( crowServiceIntegrationSaga, action.payload);
  
    if (data?.success && data?.success == true) {
     // action.callback()
      yield put({ type: CROW_SERVICE_SUCCESSED, data: data?.payload?.data?.submitCrowService });
    } else {
  
      yield put({ type: CROW_SERVICE_FAILED, data: data.payload.message });
    }
  } catch (err) {
      console.log(err);
    yield put({ type: CROW_SERVICE_FAILED, data: err?.response?.data?.data });
  }
}

export default function* watcher() {
  yield takeLatest(SERVICE_LIST_REQUESTED, watchServiceList);
  yield takeLatest(SERVICE_DETAILS_REQUESTED, watchServiceDetailsList);
  yield takeLatest(SERVICE_APPROVED_LIST_REQUESTED, watchServiceApprovedList);
  yield takeLatest(SERVICE_REQUEST_SAVE_REQUESTED, watchServiceRequestList);
  yield takeLatest(SERVICE_AUTH_FIELDS_REQUESTED, watchServiceAuthFieldsList);
  yield takeLatest(SERVICE_VENDOR_INTEGRATION_REQUESTED, watchServiceIntegration);
  yield takeLatest(DASHBOARD_REQUESTED, watchDashboard);
  yield takeLatest(ISSERVICE_ACTIVE_REQUESTED, watchIsServiceActive);
  yield takeLatest(UPDATESERVICE_STATUS_REQUESTED, watchUpdateServiceStatus);
  yield takeLatest(REQUEST_SERVICE_REQUESTED, watchRequestService);
  yield takeLatest(CROW_SERVICE_REQUESTED, watchCrowService);
}