import {
    gql
} from "@apollo/client";
import { privateClient as client } from '..';
import { commonErrorHandling } from '../Utils/index';

export default function* removeChangePriceSaga(data) {
    const query = gql`  
     query remove_catalog_ruleItem($product_id:Int,$service_id:Int){
        remove_catalog_rule(product_id:$product_id,service_id:$service_id){
          message,
          success
        }      
      }`;

    return yield client
        .query({
            query: query,
            variables: {
                service_id: data.service_id,
                product_id: data.product_id
            },
            fetchPolicy: 'no-cache'
        })
        .then(result => {
            return {
                success: true,
                payload: result
            };
        }
        ).
        catch(e => {
            commonErrorHandling(e)
            return {
                success: false,
                payload: e
            };
        });
}